import React from "react";
import "../styles/privacypolicy.css";

function Privacypolicy() {
  return (
    <>
      <div className="privacy_policy">
        <div className="container">
          <div className="privacy_policy_section">
            <h5>Privacy Policy</h5>
            <p className="privacy_policy_para1">
              At Prabhupooja.com, we prioritize the privacy of users accessing
              our platform through{" "}
              <span className="spantext">
                <a
                  href="https://www.prabhupooja.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  www.prabhupooja.com
                </a>
              </span>{" "}
              ("Prabhu Pooja" for web and app). Whether you are a registered
              user, a buyer, or an astrologer, your privacy is important to us.
              Please review our Privacy Policy carefully to understand how we
              collect, use, store, and protect the information you provide.
            </p>
            <p className="privacy_policy_para1">
              This Privacy Policy complies with the Information Technology
              (Intermediaries Guidelines) Rules, 2011, and the Information
              Technology (Reasonable Security Practices and Procedures and
              Sensitive Personal Data or Information) Rules, 2011. It governs
              the collection, usage, storage, and transfer of sensitive personal
              data or information.
            </p>

            <h6>User Consent</h6>

            <p className="privacy_policy_para1">
              By using our website, you agree to the terms outlined in this
              Privacy Policy, which may be updated periodically. If you do not
              agree with any of these terms, please refrain from using the
              website. Continued use of the site signifies your unconditional
              consent to the collection, maintenance, and use of your personal
              and other information as described here in
            </p>

            <p className="privacy_policy_para1">
              Please review this policy alongside the Terms of Use and other
              terms and conditions provided on the website.
            </p>

            <h6>Collection of Personal Information</h6>
            <p className="privacy_policy_para1">
              When creating a user profile on Prabhu Pooja, specific information
              is required, such as your phone number for OTP verification. This
              ensures the security and validity of your registration.
              Additionally, you may be asked to provide your first and last name
              and email id; however, these details are optional. If you choose
              not to share your email id, it won’t affect your ability to access
              Prabhu Pooja services.
            </p>

            <h6>Purpose and Use of Collected Information</h6>
            <p className="privacy_policy_para1">
              The information collected helps us create a personalized user
              experience tailored to your needs. For instance, while email id is
              optional, it can help provide more accurate astrological readings.
              Nevertheless, registration can be completed and services accessed
              with only a verified phone number.
            </p>

            <h6>Data Deletion</h6>
            <p className="privacy_policy_para1">
              If you decide to delete your Prabhu Pooja profile, including all
              personal information associated with it, you can do so through the
              contact us on our “What’s app”. Follow the on-screen instructions
              to complete the account deletion process.
            </p>

            <h6>
              Voice Recording and Microphone Permission and Video Permission
            </h6>
            <p className="privacy_policy_para1">
              Prabhu Pooja offers an audio and video interaction feature,
              allowing users to ask questions by recording their voice. This
              feature requires microphone and camera access, which users must
              grant. By doing so, the app can capture and process voice queries,
              offering a more natural and intuitive way to communicate.
            </p>

            <h6>Our Commitment to Privacy</h6>
            <p className="privacy_policy_para1">
              We strive to protect the privacy of all users, whether registered
              or visitors. Any personal data provided by users will only be used
              for generating astrological predictions and will not be shared for
              any other purposes, except when communicating predictions directly
              to the user. Prabhu Pooja does not sell or rent user information
              to third parties.
            </p>

            <h6>Mental Health Disclaimer</h6>
            <p className="privacy_policy_para1">
              Prabhu Pooja does not offer support or advice for users
              experiencing mental health crises, including thoughts of self-harm
              or suicide. If you are facing such challenges, we advise
              discontinuing the use of the website immediately and seeking help.
              If necessary, we may share information with law enforcement. This
              type of information is not protected by confidentiality or
              non-disclosure agreements.
            </p>

            <h6>Accuracy of Predictions</h6>
            <p className="privacy_policy_para1">
              Prabhu Pooja does not guarantee the accuracy or reliability of the
              predictions made by astrologers or the quality of gems or other
              products sold on the website. No warranties are offered on these
              services.
            </p>

            <h6>Information Collected by the Website</h6>
            <p className="privacy_policy_para1">
              <span className="spantext1">
                Personal Identifiable Information (PII):
              </span>{" "}
              During account creation or registration, Prabhu Pooja may collect
              personal information such as your full name, address, telephone
              number, email address, DOB, gender, location, and photograph. This
              information is used to secure user profiles and facilitate
              personalized services such as email and SMS notifications.
            </p>
            <p className="privacy_policy_para1">
              <span className="spantext1">
                Non-Personal Identifiable Information (NPII):{" "}
              </span>{" "}
              This may include browser type, IP address, geographic location,
              and other technical data collected when you visit the website.
              This information is used to analysed trends, troubleshoot issues,
              and improve user experience.
            </p>

            <h6>Security Measures</h6>
            <p className="privacy_policy_para1">
              Prabhu Pooja employs robust security measures to protect user
              information, including encrypted SSL communication for payment
              details. However, no data transmission over the internet is
              completely secure, so users are encouraged to protect their
              account details. Prabhu Pooja cannot be held responsible for
              unauthorized access to user information.
            </p>

            <h6>Usage of Information</h6>
            <p className="privacy_policy_para1">
              Collected information may be used to enhance the user experience,
              facilitate personalized content, monitor traffic trends, and
              improve services. We also use third-party analytics tools for
              non-personalized data analysis.
            </p>

            <h6>Confidentiality and Disclosure</h6>
            <p className="privacy_policy_para1">
              Prabhu Pooja strives to maintain confidentiality. However, we may
              disclose user information under specific circumstances, such as
              legal requirements, threats to user safety, or to protect our
              rights and property.
            </p>

            <h6>Children's Privacy</h6>
            <p className="privacy_policy_para1">
              Prabhu Pooja is not intended for use by children under 13, and we
              do not knowingly collect personal information from them. If we
              discover such information has been provided, we will delete it.
            </p>

            <h6>Safety and Security</h6>
            <p className="privacy_policy_para1">
              We are committed to safeguarding user privacy and securing
              sensitive data such as birth details and financial information.
              All transactions on Astrotalk.com are protected with encryption to
              ensure safe and secure use.
            </p>

            <h6>Disclaimer</h6>
            <p className="privacy_policy_para1">
              Prabhu Pooja is not responsible for interactions between users and
              third-party websites, even if a link to the third-party site was
              provided by Prabhu Pooja. Users are advised to review third-party
              privacy policies independently.
            </p>

            <h6>Grievance Officer</h6>
            <p className="privacy_policy_para1">
              If you have concerns or grievances, please contact:
            </p>

            <h6>
              • Contact No:
              <span className="spantext3">
                <a href="tel:+917225016699">+91 7225016699</a>
              </span>
            </h6>
            <h6>
              • Email:
              <a href="mailto:enquiry@prabhupooja.com">
                <span className="spantext3"> enquiry@prabhupooja.com</span>
              </a>
            </h6>

            <p className="privacy_policy_para1">
              This officer is appointed in accordance with the Information
              Technology Act 2000 and can address complaints or breaches of our
              Privacy Policy, Terms of Use, or other policies.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Privacypolicy;
