import React from "react";
import "../../styles/kalsharpdosh.css";
import axios from "axios";

import kalsharpimg from "../Assets/kaal-sarp-dosh.jpg";
import panditimg from "../Assets/panditji.png";
import { Link } from "react-router-dom";
import Poojapage from "../poojapage/poojapage";
import Poojapanditprofile from "../onlinepuja/poojapanditprofile";

const Kalsharpdosh = () => {
  const handlePayment = async () => {
    try {
      const response = await axios.post('http://localhost:3002/api/v1/payment/create-payment', {
        amount: '10.00',
        description: 'Sample Transaction'
      });
      window.location.replace(response.data.redirectUrl);
    } catch (error) {
      console.error('Error initiating PayPal payment:', error);
    }
  };

  return (
    <>
      {/* <div className="category_heading">
        <div className="container">
          <div className="row">
            <div className="col-sm-5">
              <div className="product_image">
                <img src={kalsharpimg} alt="img" />
              </div>
            </div>
            <div className="col-sm-5">
              <h1 className="product_content">Kaal Sarp Dosh</h1>
              <div className="product_name">
                <p>
                  Remove Dosha & Get Better Career, Relationships & Finances
                </p>
              </div>

              <div className="timeshedule_section">
                <span>09 days left</span>
              </div>

              <div className="booknow-btn">
                <button className="booknoww" onClick={handlePayment}>Book Now</button> 
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <Poojapage />

      <div className="about_according">
        <div className="container">
          <div className="row">
            <div className="col-sm-6">
              {/* <div className="details_content">
                <div className="pandit_profile">
                  <div className="profile_pic">
                    <img src={panditimg} alt="" />
                  </div>
                  <div className="profile_details">
                    <span className="profile_name">NAME</span>
                    <span className="profile_skill">Vedic Guru</span>
                  </div>
                </div>

                <p className="profile_bio">
                  “NAME”, a renowned Indian Vedic Astrologer, offers expert
                  guidance to help you achieve a stable and clear life. With his
                  deep knowledge of Vedic astrology, “NAME” provides
                  personalized attention and simple yet effective remedies
                  tailored to your unique needs.
                </p>
              </div> */}

              <Poojapanditprofile />
            </div>

            <div className="col-sm-6">
              <div className="next_section">
                <h2>What are the benefits?</h2>
                <div className="next_details">
                  <ul>
                    <li>The primary aim of performing this Puja is to mitigate and
                    transform the adverse effects of Kaal Sarp Yog into positive
                    outcomes.</li>
                    <li>It eliminates potential barriers and obstacles to one's
                    success.</li>
                    <li>It addresses issues causing delays in marriage.</li>
                    <li>The Puja also ensures stability in both personal and
                    professional life.</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-sm-6">
              <div className="next_section">
                <h2>How will it happen?</h2>
                <div className="next_details">
                  <ul>
                    <li> Book the Pooja. </li>
                    <li>
                      Schedule the Pooja. Provide your name and surname/Gotra
                      for the Sankalp, which the Pandit will take.
                    </li>
                    <li>
                      Have a Dupatta, Handkerchief, or Towel ready to cover your
                      head at the beginning of the Pooja.
                    </li>
                    <li>
                      Listen attentively to the Pooja and seek blessings with
                      devotion.
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-sm-6">
              <div className="next_section">
                <h2>About Kaal Sarp Dosh</h2>
                <div className="next_details">
                  <ul>
                    <li>
                      Kaal Sarp Dosh arises as a curse from the bad deeds done
                      in a previous life, bringing various troubles to the
                      person's current life.
                    </li>
                    <li>
                      The presence of this dosha causes continuous issues
                      related to children, health, home, family, and finances,
                      making the person very distressed.
                    </li>
                    <li>
                      This dosha leads to mental troubles and causes bad dreams.
                    </li>
                    <li>
                      Kaal Sarp Dosh is a result of the inauspicious effects of
                      Rahu and Ketu.
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-sm-6">
              <div className="next_section">
                <h2>What should you do after Pooja to get maximum benefits?</h2>
                <div className="next_details">
                  <ul>
                    <li> Recite the important Shiva Mantra: “Om Namah Shivaya”</li>
                    <li> Do the Pooja every month to get maximum benefits. </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-sm-6">
              <div className="next_section">
                <h2>Why Book with Prabhu Pooja?</h2>
                <div className="next_details">
                  <ul>
                    <li>Prabhu Pooja is India's largest Devotion tech platform,
                    offering access to top astrologers and Pandits.</li>
                    <li>Our Pooja services feature the best Pandits on the
                    platform to ensure you receive the maximum benefits.</li>
                    <li>This Pooja includes family participation and covers
                    Dakshina as well.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Kalsharpdosh;
