import React, { useState, useEffect } from 'react';
import api from '../Axios/api';
import "../../styles/yogabooking.css";

function Yogabookingpage() {
  const [yoga, setYoga] = useState([]);
  const user = JSON.parse(localStorage.getItem('users'));

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await api.get(`/yoga/getuser/${user.id}`); 
        console.log(response.data.data);

        // Update state based on the response structure
        if (Array.isArray(response.data.data)) {
          setYoga(response.data.data);
        } else {
          setYoga([response.data.data]);
        }
      } catch (error) {
        console.error('Error fetching user details:', error.message);
      }
    };

    fetchUser();
  }, [user.id]);

  return (
    <div className="booking-page">
      <h1 className="page-title">Your Yoga Bookings</h1>
      {yoga.length > 0 ? (
        <div className="booking-list">
          {yoga.map((booking, index) => (
            <div className="booking-card" key={index}>
              <div className="booking-details">
                <h2 className="yoga-name">{booking.name}</h2>
                <p className="price">₹{booking.amount}</p>
                <p className="order-date">
                  <strong>Order Date:</strong>{' '}
                  {new Date(booking.booking_date).toLocaleDateString('en-IN', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                  })}
                </p>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p className="no-bookings">No yoga bookings found.</p>
      )}
    </div>
  );
}

export default Yogabookingpage;
