import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../Axios/api";

const Otp = () => {
  const [otp, setOtp] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   try {
  //     const response = await api.post("/users/verifyOtp", { otp });

  //     console.log("OTP verified:", response.data);
  //     const token = response.data.auth;

  //     localStorage.setItem("token", token);
  //     localStorage.setItem("isVerified", "true");

  //     navigate("/");

  //   } catch (error) {
  //     console.error("OTP verification failed:", error);
  //     if (error.response && error.response.data && error.response.data.message) {
  //       setErrorMessage(error.response.data.message);
  //     } else {
  //       setErrorMessage("OTP verification failed. Please try again later.");
  //     }
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await api.post("/users/verifyOtp", { otp });

      console.log("OTP verified:", response.data);
      const token = response.data.auth;

      localStorage.setItem("token", token);
      localStorage.setItem("isVerified", "true");

      // Emit a custom event to notify that the user has logged in
      window.dispatchEvent(new Event("loginStatusChanged"));

      navigate("/");
    } catch (error) {
      console.error("OTP verification failed:", error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage("OTP verification failed. Please try again later.");
      }
    }
  };

  return (
    <div className="addUser">
      <h3>Sign in</h3>
      <form className="addUserForm" onSubmit={handleSubmit}>
        <div className="inputGroup">
          <label htmlFor="Password">OTP:</label>
          <input
            type="text"
            autoComplete="off"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
            required
            placeholder="Enter your OTP"
          />
          <button type="submit" className="btn btn-primary">
            Login
          </button>
        </div>
        {errorMessage && <p>{errorMessage}</p>}
      </form>
    </div>
  );
};

export default Otp;
