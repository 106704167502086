import React, { useState, useEffect } from 'react';
import api from '../Axios/api';
import "../../styles/booking.css";

function Ecomerceookingpage() {
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const user = JSON.parse(localStorage.getItem("users"));

  useEffect(() => {
    const fetchBookings = async () => {
      try {
        setLoading(true);

        const response = await api.get(`/orders/getbyuser/${user.id}`);
        setBookings(response.data.data);
        setLoading(false);
      } catch (err) {
        console.error(err);
        setError('Failed to fetch bookings');
        setLoading(false);
      }
    };
    fetchBookings();
  }, [user.id]);

  if (loading) {
    return <div className="loading"><p>Loading...</p></div>;
  }

  if (error) {
    return <div className="error"><p>{error}</p></div>;
  }

  return (
    <div className="order-container">
      <h1 className="page-title">Your Orders</h1>
      <div className="decorative-line"></div>
      {bookings.length > 0 ? (
        <div className="order-list">
          {bookings.map((booking, index) => (
            <div className="order-card" key={index}>
              <div className="order-card-left">
                <img
                  src={booking.productImage}
                  alt={booking.productName}
                  className="order-image"
                />
              </div>
              <div className="order-card-right">
                <h2 className="product-name">{booking.productName}</h2>
                <p className="product-price">₹{booking.productPrice}</p>
                <p className="order-date">Ordered on: {new Date(booking.latestOrderDate).toLocaleDateString()}</p>
                {/* <p className="order-status">Status: <span className="status-pending">{booking.orderStatus}</span></p> */}
                {/* <div className="order-actions">
                <button className="action-btn">Track Order</button>
                <button className="action-btn cancel-btn">Cancel Order</button>
              </div> */}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p>No product bookings found.</p>
      )}
    </div>
  );
}

export default Ecomerceookingpage;
