import React from "react";
import "../../styles/kalsharpdosh.css";

// import sundarkandimg from "../Assets/sundarkandd-image.jpeg";
// import panditimg from "../Assets/panditji.png";
// import { Link } from "react-router-dom";
import Poojapage from "../poojapage/poojapage";
import Poojapanditprofile from "../onlinepuja/poojapanditprofile";

const Sundarkand = () => {
  return (
    <>
      {/* <div className="category_heading">
        <div className="container">
          <div className="row">
            <div className="col-sm-5">
              <div className="product_image">
                <img src={sundarkandimg} alt="img" />
              </div>
            </div>
            <div className="col-sm-5">
              <h1 className="product_content">Sundarkand Path Pooja</h1>
              <div className="product_name">
                <p>
                  Sundarkand Path Pooja se mangal aur shubh karyaon mein saflta
                  milti hai.
                </p>
              </div>

              <div className="timeshedule_section">
                <span>09 days left</span>
              </div>

              <div className="booknow-btn">
              <Link to="/login"> <button className="booknoww">Book Now</button> </Link>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <Poojapage />

      <div className="about_according">
        <div className="container">
          <div className="row">
            <div className="col-sm-6">
              {/* <div className="details_content">
                <div className="pandit_profile">
                  <div className="profile_pic">
                    <img src={panditimg} alt="" />
                  </div>
                  <div className="profile_details">
                    <span className="profile_name">NAME</span>
                    <span className="profile_skill">Vedic Guru</span>
                  </div>
                </div>

                <p className="profile_bio">
                  “NAME” is a vedic expert with 5+ years of experience who
                  specializes in relationship problems and adivses 100%
                  sucessful remedies.
                </p>
              </div> */}

              <Poojapanditprofile />
            </div>

            <div className="col-sm-6">
              <div className="next_section">
                <h2>What are the benefits?</h2>
                <div className="next_details">
                  <ul>
                    <li>Brings peace, harmony, and positivity into life.</li>
                    <li>Helps overcome obstacles and fulfill desires.</li>
                    <li>
                      Provides spiritual strength and blessings from Lord
                      Hanuman.
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-sm-6">
              <div className="next_section">
                <h2>How will it happen?</h2>
                <div className="next_details">
                  <ul>
                    <li>Book the Pooja.</li>
                    <li>
                      Schedule the Pooja. Provide your name and surname/Gotra
                      for the Sankalp, which the Pandit will take.
                    </li>
                    <li>
                      Have a Dupatta, Handkerchief, or Towel ready to cover your
                      head at the beginning of the Pooja.
                    </li>
                    <li>
                      Listen attentively to the Pooja and seek blessings with
                      devotion.
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-sm-6">
              <div className="next_section">
                <h2>About SUNDARKAND PATH POOJA</h2>
                <div className="next_details">
                  <ul>
                    <li>
                      It venerates Lord Hanuman for courage, strength, and
                      success in life's challenges.
                    </li>
                    <li>
                      The puja includes chanting verses, offering prayers, and
                      seeking divine blessings.
                    </li>
                    <li>
                      Its aim is to remove obstacles and invite prosperity and
                      peace.
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-sm-6">
              <div className="next_section">
                <h2>What should you do after Pooja to get maximum benefits?</h2>
                <div className="next_details">
                  <ul>
                    <li>
                      Distribute prasad (sacred food offering) to attendees.
                    </li>
                    <li>Read or listen to the Sundar Kand text regularly.</li>
                    <li>
                      Maintain a charitable attitude and help those in need.
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-sm-6">
              <div className="next_section">
                <h2>Why Book with Prabhu Pooja?</h2>
                <div className="next_details">
                  <ul>
                    <li>
                      Prabhu Pooja is India's largest Devotion tech platform,
                      offering access to top astrologers and Pandits.
                    </li>
                    <li>
                      Our Pooja services feature the best Pandits on the
                      platform to ensure you receive the maximum benefits.
                    </li>
                    <li>
                      This Pooja includes family participation and covers
                      Dakshina as well.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sundarkand;
