import React, { useState, useEffect } from "react";
import "../../styles/temple.css";
import { Link, useNavigate } from "react-router-dom";
import mapimg from "../Assets/map-temple-image.png";
import { IoCheckmark } from "react-icons/io5";
import api from "../Axios/api";

const Temple = () => {
  const [temples, setTemples] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("users"));

  useEffect(() => {
  
    const fetchTemples = async () => {
      try {
        const response = await api.get("/temple/get");
        setTemples(response.data.data);
        console.log(response.data.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching temples:", error);
        setLoading(false);
      }
    };

    fetchTemples();
  }, []);

  const handleBookClick = (templeId, price) => {
    if (!user) {
      navigate("/login");
    } else {
      navigate("/booknowform", { state: { templeId, price } });
    }
  };

  if (loading) {
    return <div>Loading...</div>; // Or a loading spinner
  }

  return (
    <div>
      <div className="temple-header">
        <div className="container">
          <div className="row">
            <div className="col-sm-7">
              <div className="temple-intro">
                <h1>Visit the holy places and temples of India.</h1>
              </div>
              <div className="temple-features">
                <p className="feature-item">
                  <IoCheckmark className="feature-icon" />
                  Learn about their culture and history.
                </p>
                <p className="feature-item">
                  <IoCheckmark className="feature-icon" />
                  Find temples of your favorite gods.
                </p>
                <p className="feature-item">
                  <IoCheckmark className="feature-icon" />
                  Do charity work and donate to these temples.
                </p>
              </div>
            </div>

            <div className="col-sm-5">
              <Link to="#">
                <img
                  src={mapimg}
                  alt="Map of Temples"
                  className="temple-map-image"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="temple-gallery">
        <div className="container">
          <div className="row">
            {temples.map((temple) => (
              <div className="col-sm-4" key={temple.id}>
                <article className="temple-gallery-item">
                  <Link to={
                    `/temple/${temple.id}`
                }>
                    <img
                      src={temple.image}
                      alt={temple.name}
                      height={245}
                      className="temple-image"
                    />
                  </Link>
                  <div className="temple-info">
                    <h3 className="temple-title">{temple.name}</h3>
                    <p className="temple-description">{temple.location}</p>
                    <div className="temple-price">₹ {temple.price}</div>
                    <div className="temple-actions">
                      <Link className="temple-action-link" to={`/temple/${temple.id}`}>
                        <button>VIEW</button>
                      </Link>
                      <button
                        className="temple-action-link1"
                        onClick={() => handleBookClick(temple.id, temple.price)}
                      >
                        BOOK
                      </button>
                    </div>
                  </div>
                </article>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Temple;
