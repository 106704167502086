import React, { useEffect, useState } from "react";
import "../../styles/onlinepuja.css";
import { Link } from "react-router-dom";
import api from "../Axios/api";

const Onlinepuja = () => {
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await api.get("user/onlinePuja/get");

        if (response.data.success) {
          const fetchedServices = response.data.data;

          // Fetch ratings for each service
          const servicesWithRatings = await Promise.all(
            fetchedServices.map(async (service) => {
              try {
                const ratingResponse = await api.get(
                  `/feedback/rating/${service.id}`
                );
                console.log(ratingResponse);
                if (ratingResponse.data.success) {
                  return {
                    ...service,
                    rating: ratingResponse.data.data.averageRating,
                  };
                }
              } catch (err) {
                console.error(`Error fetching ratings for service ${service.id}:`, err);
              }
              return { ...service, rating: 0 }; // Default rating if error occurs
            })
          );

          setServices(servicesWithRatings);
        } else {
          setError(response.data.message || "Failed to fetch services.");
        }
      } catch (error) {
        setError("Error fetching services. Please try again.");
        console.error("Error fetching services:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchServices();
  }, []);

  function slugify(text) {
    return text.toString().toLowerCase().replace(/\s+/g, ""); // Remove spaces and make lowercase
  }
  

  const renderStars = (rating) => {
    const fullStars = Math.floor(rating); // Full stars
    const emptyStars = 5 - fullStars; // Remaining stars
    return (
      <>
        {"★".repeat(fullStars)}
        {"☆".repeat(emptyStars)}
      </>
    );
  };

  return (
    <>
      <div className="sub_header">
        <div className="container">
          <div className="subheader_inner">
            <div className="subheader_text">
              <h1>Online Pooja</h1>
            </div>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link className="btn-link" to="/">
                    Home
                  </Link>
                </li>
                <li className="breadcrumb-item active">Online Pooja</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      <div className="puja_category">
        <div className="container">
          <div className="puja_title">POOJA CATEGORIES</div>

          <div className="row" style={{ marginTop: "50px" }}>
            {services.map((service) => (
              <div className="col-sm-3" key={service.id}>
                <div className="tp-box">
                  <div className="tp-img">
                    <Link to={`/${slugify(service.name)}/${service.id}`}>
                      <img src={service.image} alt={service.name} />
                    </Link>
                  </div>

                  <div className="tp-box-content">
                    <h6>{service.name}</h6>
                    <div className="price-booknow">
                      <div className="price-grp">
                        <p className="current_price">
                          <strong>
                            <span>Rs.{service.price}</span>
                          </strong>
                        </p>
                      </div>
                      <Link
                        className="booknow_btn"
                        to={`/${slugify(service.name)}/${service.id}`}
                      >
                        Book Now
                      </Link>
                    </div>

                    {/* Rating */}
                    <div className="rating">
                      <Link to={`/${slugify(service.name)}/${service.id}`}>
                        <span>{renderStars(service.rating || 0)}</span>{" "}
                      </Link>
                      <span className="rating-number">
                        ({service.rating ? parseFloat(service.rating).toFixed(1) : "0.0"})
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {loading && <p>Loading...</p>}
          {error && <p className="error-message">{error}</p>}
        </div>
      </div>
    </>
  );
};

export default Onlinepuja;
