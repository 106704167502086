import React from "react";
import "../../styles/kalsharpdosh.css";

// import rahuketuimg from "../Assets/rahu-ketu-pooja.jpeg";
// import panditimg from "../Assets/panditji.png";
// import { Link } from "react-router-dom";
 import Poojapage from "../poojapage/poojapage";
import Poojapanditprofile from "../onlinepuja/poojapanditprofile";

const Rahuketupooja = () => {
  return (
    <>
      {/* <div className="category_heading">
        <div className="container">
          <div className="row">
            <div className="col-sm-5">
              <div className="product_image">
                <img src={rahuketuimg} alt="img" />
              </div>
            </div>
            <div className="col-sm-5">
              <h1 className="product_content">Rahu Ketu Pooja</h1>
              <div className="product_name">
                <p>Removes Negativity & Solves Problems</p>
              </div>

              <div className="timeshedule_section">
                <span>09 days left</span>
              </div>

              <div className="booknow-btn">
               <Link to="/login"> <button className="booknoww">Book Now</button> </Link>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <Poojapage />
      
      

      <div className="about_according">
        <div className="container">
          <div className="row">
            <div className="col-sm-6">
              <Poojapanditprofile />
              {/* <div className="details_content">
                <div className="pandit_profile">
                  <div className="profile_pic">
                    <img src={panditimg} alt="" />
                  </div>
                  <div className="profile_details">
                    <span className="profile_name">NAME</span>
                    <span className="profile_skill">Vedic Guru</span>
                  </div>
                </div>

                <p className="profile_bio">
                  “NAME” is a vedic expert with 5+ years of experience who
                  specializes in relationship problems and adivses 100%
                  sucessful remedies.
                </p>
              </div> */}
            </div>

            <div className="col-sm-6">
              <div className="next_section">
                <h2>What are the benefits?</h2>
                <div className="next_details">
                  <ul>
                    <li>Clearing obstacles from one's path.</li>
                    <li>Shielding against.</li>
                    <li>
                      Enhancing clarity and wisdom for better decision-making.
                    </li>
                    <li>Fostering physical and mental wellness.</li>
                    <li>Cultivating peace and harmony in life.</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-sm-6">
              <div className="next_section">
                <h2>How will it happen?</h2>
                <div className="next_details">
                  <ul>
                    <li>Book the Pooja.</li>
                    <li>
                      Schedule the Pooja. Provide your name and surname/Gotra
                      for the Sankalp, which the Pandit will take.
                    </li>
                    <li>
                      Have a Dupatta, Handkerchief, or Towel ready to cover your
                      head at the beginning of the Pooja.
                    </li>
                    <li>
                      Listen attentively to the Pooja and seek blessings with
                      devotion.
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-sm-6">
              <div className="next_section">
                <h2>About Rahu & Ketu Grah</h2>
                <div className="next_details">
                  <ul>
                    <li>Rahu and Ketu denote the points of intersection of the
                    paths of the Sun and the Moon as they move on the celestial
                    sphere.</li>
                    <li>Therefore, Rahu and Ketu are respectively called the north
                    and the south lunar nodes.</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-sm-6">
              <div className="next_section">
                <h2>What should you do after Pooja to get maximum benefits?</h2>
                <div className="next_details">
                  <ul>
                    <li>Chant the significant mantras for Rahu and Ketu. "ॐ भ्रां
                    भ्रीं भ्रौं सः राहवे नमः॥"</li>
                    <li>Do the Pooja every month to get maximum benefits.</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-sm-6">
              <div className="next_section">
                <h2>Why Book with Prabhu Pooja?</h2>
                <div className="next_details">
                  <ul>
                    <li>Prabhu Pooja is India's largest Devotion tech platform,
                    offering access to top astrologers and Pandits.</li>
                    <li>Our Pooja services feature the best Pandits on the
                    platform to ensure you receive the maximum benefits.</li>
                    <li>This Pooja includes family participation and covers
                    Dakshina as well.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Rahuketupooja;
