import React from "react";

const Astrologyform = () => {
  return (
    <>
      <div className="addUser">
        <h3>Personal Details</h3>
        <form className="addUserForm">
          <div className="inputGroup">
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              id="name"
              autoComplete="off"
              placeholder="Enter your name"
            />

            <label htmlFor="role">Gender:</label>
            <select
              id="role"
              style={{ padding: "10px" }}
              className="role_select"
            >
              <option value="0">Male</option>
              <option value="1">Female</option>
            </select>

            <label htmlFor="number">Date Of Birth:</label>
            <input
              type="text"
              id="number"
              autoComplete="off"
              placeholder="Enter your date of birth"
            />

            <label htmlFor="email">Time Of Birth:</label>
            <input
              type="text"
              id="email"
              autoComplete="off"
              placeholder="Enter your time of birth"
            />

            <label htmlFor="city">Place Of Birth:</label>
            <input
              type="text"
              id="city"
              autoComplete="off"
              placeholder="Enter your place of birth"
            />

            <button type="submit" className="btn btn-success">
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default Astrologyform;
