import React from "react";
import profile_pic from "../Assets/profile-pic.png";

function Loginprofile() {
  return (
    <>
      <div className="profile_section">
        <div className="container">
          <div className="profile_box">
            <img src={profile_pic} alt="" className="profile_img" />
            <h6 className="profile_titlee">Kiran Malviya</h6>
            <p className="profile_logoutbtn">Logout</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Loginprofile;
