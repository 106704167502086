import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../styles/cart.css";
import prasadimg from "../Components/Assets/prasadimg.webp";
import api from "./Axios/api";

const Cart = () => {
  const [cartItems, setCartItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const user = JSON.parse(localStorage.getItem("users"));
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCartItems = async () => {
      try {
        const response = await api.get(`/cart/getcart/${user.id}`);
        console.log(response.data.data);
        setCartItems(response.data.data);
      } catch (err) {
        setError("Failed to fetch cart items");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchCartItems();
  }, [user.id]);



  const handleRemoveItem = async (id) => {
    try {
      console.log(id);
      const response = await api.delete(`/cart/delete/${id}`);
      console.log('m chal rh hu',response.data);
      if (response.data.success) {
        const updatedCartResponse = await api.get(`/cart/getcart/${user.id}`);
        console.log(updatedCartResponse.data.data);
        if (updatedCartResponse.data.success) {
          setCartItems(updatedCartResponse.data.data);  // Update the state with the updated cart items
        } else {
          console.error("Failed to fetch updated cart:", updatedCartResponse.data.message);
        }
      } else {
        console.error("Failed to remove item:", response.data.message);
      }
    } catch (error) {
      console.error("Error while removing item:", error);
    }
  };


  const handleIncreaseQuantity = (id) => {
    const updatedItems = cartItems.map((item) =>
      item.productId === id ? { ...item, quantity: item.quantity + 1 } : item
    );
    setCartItems(updatedItems);
  };

  const handleDecreaseQuantity = (id) => {
    const updatedItems = cartItems.map((item) =>
      item.productId === id && item.quantity > 1
        ? { ...item, quantity: item.quantity - 1 }
        : item
    );
    setCartItems(updatedItems);
  };

  const handleCheckout = () => {
    const productId = cartItems.map((item) => item.productId); // Get all product IDs
    const quantity = cartItems.map((item) => item.quantity); // Get all quantities
    const totalPrice = cartItems.reduce(
      (total, item) => total + item.price * item.quantity,
      0
    ); // Calculate total price
    const productData = cartItems.map((item) => ({
      productId: item.productId,
      productName: item.productName,
      price: item.price,
      quantity: item.quantity,
      totalPrice: item.price * item.quantity,
    }));

    // Navigate to the checkout page with the data
    navigate("/checkout", {
      state: {
        productId: productId,
        quantity: quantity,
        totalPrice: totalPrice,
        user: user,
        booking:'cart'
      },
    });
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="cart-page">
      {cartItems.length === 0 ? (
        <p>Your cart is empty</p>
      ) : (
        <div className="cart-container">
          <div className="cart-items1">
            {cartItems.map((item, index) => (
              <div key={index} className="cart-item1">
                <img src={item.image || prasadimg} alt={item.productName} />
                <div className="cart-item-details1">
                  <h2>{item.productName}</h2>
                  <p>Price: ₹{item.price}</p>
                  <div className="quantity-control">
                    <button onClick={() => handleDecreaseQuantity(item.productId)}>-</button>
                    <span>{item.quantity}</span>
                    <button onClick={() => handleIncreaseQuantity(item.productId)}>+</button>
                  </div>
                </div>
                <button onClick={() => handleRemoveItem(item.id)} className="remove-button">
                  Remove
                </button>
              </div>
            ))}

          </div>
          <div className="order-summary">
            <h2>Order Summary</h2>
            <div className="summary-itemm">
              <span>Total Products:</span>
              <span>{cartItems.length}</span>
            </div>
            <div className="summary-item1">
              <span>Subtotal:</span>
              <span>
                ₹
                {cartItems.reduce(
                  (total, item) => total + item.price * item.quantity,
                  0
                )}
              </span>
            </div>
            <div className="summary-item">
              <span>Tax:</span>
              <span>₹0</span>
            </div>
            <div className="summary-item total">
              <span>TOTAL:</span>
              <span>
                ₹
                {cartItems.reduce(
                  (total, item) => total + item.price * item.quantity,
                  0
                )}
              </span>
            </div>
            <button onClick={handleCheckout} className="checkout-button">
              Proceed to Checkout
            </button>
          </div>
        </div>
      )}
      <Link to="/ecommerce" className="continue-shopping">
        Continue
      </Link>
    </div>
  );
};

export default Cart;