import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import api from "../Axios/api";
import BookPoojaForm from "../onlinepuja/bookpoojaform";
import StarRatings from "react-star-ratings";
import RatingsPopup from "./ratingpopup";

const Poojapage = () => {
  const [pujaDetails, setPujaDetails] = useState(null);
  const [error, setError] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [bookingDate, setBookingDate] = useState("");
  const [currentDate, setCurrentDate] = useState(""); // State to store the current date
  const { id } = useParams();
  const [feedbacks, setFeedbacks] = useState([]);
  const [averageRating, setAverageRating] = useState(0);
  const [totalRatings, setTotalRatings] = useState(0);
  const [showRatingsPopup, setShowRatingsPopup] = useState(false);
  const user = JSON.parse(localStorage.getItem("users"));
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const today = new Date();
    const formattedDate = today.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
    setCurrentDate(formattedDate);
  }, []);

  useEffect(() => {
    if (id) {
      fetchPujaDetails();
      fetchBookingDate();
      fetchPujaRatings();
    } else {
      console.error("ID is undefined");
    }
  }, [id]);

  useEffect(() => {
    if (id && pujaDetails) {
      checkPaymentStatus();
    }
  }, [id, pujaDetails]);

  const fetchBookingDate = async () => {
    try {
      const response = await api.get(`/pooja/bookingDate/${id}/${user.id}`);
      if (response.data.success) {
        const fullDate = new Date(response.data.data[0].bookingdate);
        const formattedDate = new Intl.DateTimeFormat("en-GB", {
          day: "numeric",
          month: "long",
          year: "numeric",
        }).format(fullDate);
        setBookingDate(formattedDate);
      } else {
        setBookingDate(""); // If no booking date is available
      }
    } catch (error) {
      console.error("Error fetching booking date:", error);
      setBookingDate(""); // Default to empty if an error occurs
    }
  };

  const fetchPujaRatings = async () => {
    try {
      const response = await api.get(`/feedback/rating/${id}`);
      if (response.data.success) {
        const { averageRating, totalRatings, feedbacks } = response.data.data;
        setAverageRating(averageRating);
        setTotalRatings(totalRatings);
        setFeedbacks(feedbacks);
      }
    } catch (error) {
      console.error("Error fetching ratings:", error);
    }
  };

  const checkPaymentStatus = async () => {
    try {
      const response = await api.get(`/payment/status/${user.id}/${id}`);
      if (response.data.success) {
        setPaymentStatus(response.data.status);
      } else {
        setPaymentStatus(null);
      }
    } catch (error) {
      console.error("Error retrieving payment status:", error);
      setPaymentStatus(null);
    }
  };

  const fetchPujaDetails = async () => {
    try {
      const response = await api.get(`/user/onlinePuja/get/${id}`);
      if (response.data.success) {
        const puja = response.data.data[0];
        setPujaDetails(puja);
      } else {
        setError("Failed to fetch details");
      }
    } catch (error) {
      setError("An error occurred while fetching puja details");
      console.error("Error fetching puja details:", error);
    }
  };

  const handleLivePooja = async () => {
    try {
      const roomName = pujaDetails.name.replace(/\s+/g, "");
      const response = await api.post("/live_stream/token", {
        user_id: user.id,
        room: roomName,
      });
      if (response.data.success) {
        navigate(
          `/videoCall/${encodeURIComponent(roomName)}/${response.data.token}`
        );
      } else {
        console.error("Failed to fetch token:", response.data.message);
      }
    } catch (error) {
      console.error("Error initiating live pooja:", error);
    }
  };

  const isSameDate = (date1, date2) => {
    const d1 = new Date(date1).toDateString();
    const d2 = new Date(date2).toDateString();
    return d1 === d2;
  };

  const handleOpenPopup = () => {
    if (!user) {
      navigate("/login");
    } else {
      setShowPopup(true);
    }
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleFeedbackNavigation = () => {
    navigate("/feedbackform", {
      state: { pujaId: pujaDetails.id },
    });
  };

  return (
    <div className="category_heading">
      <div className="container">
        {pujaDetails ? (
          <div className="row">
            <div className="col-sm-5">
              <div className="product_image">
                <img src={pujaDetails.image} alt={pujaDetails.name} />
              </div>
            </div>
            <div className="col-sm-5">
              <h1 className="product_content">{pujaDetails.name}</h1>
              <div className="product_name">
                <p>Removes Negativity & Solves Problems</p>
              </div>
              <div className="rating-section">
                <StarRatings
                  rating={parseFloat(averageRating)}
                  starRatedColor="gold"
                  starEmptyColor="gray"
                  starDimension="20px"
                  starSpacing="2px"
                  numberOfStars={5}
                  name="averageRating"
                />
                <p>{`${totalRatings} ratings`}</p>
                <button
                  onClick={() => setShowRatingsPopup(true)}
                  className="view-comments-btn"
                >
                  View Ratings & Comments
                </button>
              </div>
              {/* <div className="timeshedule_section">
                {!bookingDate ? (
                  <div className="booknow-btn">
                    <button className="booknoww" onClick={handleOpenPopup}>
                      Book Now
                    </button>
                  </div>
                ) : isSameDate(currentDate, bookingDate) ? (
                  <>
                    <span className="pooja-booking-info">
                      Your booking is on: {bookingDate}
                    </span>
                    <div className="bothbtn">
                      <button
                        className="join-now-btn"
                        onClick={handleLivePooja}
                      >
                        Join Now
                      </button>
                      <button
                        className="feedback-btn"
                        onClick={handleFeedbackNavigation}
                      >
                        Feedback
                      </button>
                    </div>
                  </>
                ) : new Date(bookingDate) < new Date() ? (
                  <div className="booknow-btn">
                    <button className="booknoww" onClick={handleOpenPopup}>
                      Book Now
                    </button>
                  </div>
                ) : (
                  <div className="pooja-booking-info">
                    Booking date: {bookingDate}
                  </div>
                )}
              </div> */}
              <div className="timeshedule_section">
                {!bookingDate ? (
                  <div className="booknow-btn">
                    <button className="booknoww" onClick={handleOpenPopup}>
                      Book Now
                    </button>
                  </div>
                ) : isSameDate(currentDate, bookingDate) ? (
                  <>
                    <span className="pooja-booking-info">
                      Your booking is on: {bookingDate}
                    </span>
                    <div className="bothbtn">
                      <button
                        className="join-now-btn"
                        onClick={handleLivePooja}
                      >
                        Join Now
                      </button>
                      <button
                        className="feedback-btn"
                        onClick={handleFeedbackNavigation}
                      >
                        Feedback
                      </button>
                    </div>
                  </>
                ) : new Date(bookingDate) < new Date() ? (
                  <div className="booknow-btn">
                    <button className="booknoww" onClick={handleOpenPopup}>
                      Book Now
                    </button>
                  </div>
                ) : (
                  <div className="pooja-booking-info">
                    Booking date: {bookingDate}
                  </div>
                )}
              </div>

              {/* <div className="rating-section">
                <StarRatings
                  rating={parseFloat(averageRating)}
                  starRatedColor="gold"
                  starEmptyColor="gray"
                  starDimension="20px"
                  starSpacing="2px"
                  numberOfStars={5}
                  name="averageRating"
                />
                <p>{`${totalRatings} ratings`}</p>
                <button
                  onClick={() => setShowRatingsPopup(true)}
                  className="view-comments-btn"
                >
                  View Ratings & Comments
                </button>
              </div> */}
            </div>
          </div>
        ) : (
          <p>Loading...</p>
        )}
        {error && <p className="error">{error}</p>}
      </div>
      {showPopup && (
        <div className="popup-overlay">
          <div className="popup-content">
            <div className="popup-header">
              <h2>Book Pooja</h2>
              <button className="close-btn" onClick={handleClosePopup}>
                &times;
              </button>
            </div>
            <BookPoojaForm data={pujaDetails} onClose={handleClosePopup} />
          </div>
        </div>
      )}
      {showRatingsPopup && (
        <RatingsPopup id={id} onClose={() => setShowRatingsPopup(false)} />
      )}
    </div>
  );
};

export default Poojapage;
