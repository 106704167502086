import React, { useState, useEffect } from 'react';
import api from '../Axios/api';
import "../../styles/templebooking.css";

function Templebookingpage() {
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const user = JSON.parse(localStorage.getItem("users"));

  useEffect(() => {
    const fetchBookings = async () => {
      try {
        const response = await api.get(`temple/user/${user.id}`);
        const data = response.data;

        if (data.success && data.data.length > 0) {
          setBookings(data.data);
        } else {
          setBookings([]);
        }
      } catch (err) {
        setError("An error occurred while fetching the bookings.");
        console.error("Error fetching bookings:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchBookings();
  }, [user.id]);

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
    <div className="booking-page">
      <h1 className="page-title">Your Temple Bookings</h1>
      {bookings.length > 0 ? (
        <div className="booking-list">
          {bookings.map((booking, index) => (
            <div className="booking-card" key={index}>
              <div className="booking-image">
                <img src={booking.templeImage} alt={booking.productName} />
              </div>
              <div className="booking-details">
                <h2 className="temple-name">{booking.templeName}</h2>
                <p className="price">₹{booking.templePrice}</p>
                <p className="address">
                  <strong>Address:</strong> {booking.templeAddress}
                </p>
                <p className="order-date">
                  <strong>Order Date:</strong>{' '}
                  {new Date(booking.bookingDate).toLocaleDateString('en-IN', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                  })}
                </p>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p>No bookings found.</p>
      )}
    </div>
  );
}

export default Templebookingpage;
