import React, { useEffect, useState } from "react";
// import panditimg from "../Assets/panditimg.jpg";
import api from "../Axios/api";
import { useParams } from "react-router-dom";
import "../../styles/panditprofile.css";
import { Link, useNavigate } from "react-router-dom";
import loader from "../Assets/loader.svg";

const PanditProfile = () => {
  const [panditData, setPanditData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { id } = useParams();
  const [balance, setBalance] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [requestStatus, setRequestStatus] = useState(null);
  const navigate = useNavigate();

  console.log("Pandit ID is", id);

  const user = JSON.parse(localStorage.getItem("users"));

  useEffect(() => {
    const fetchPanditData = async () => {
      try {
        const response = await api.get(`/pandit/id/${id}`);
        console.log("chal rh hu");
        setPanditData(response.data.data);
        setLoading(false);
      } catch (err) {
        setError("Error fetching data");
        setLoading(false);
      }
    };
    fetchPanditData();
  }, [id]);
  useEffect(() => {
    const fetchUserBalance = async () => {
      if (user && user.id) {
        try {
          const response = await api.get(`/users/balance/${user.id}`);
          if (response.data.success) {
            setBalance(response.data.balance);
          } else {
            setError(response.data.message || "Failed to fetch balance.");
          }
        } catch (error) {
          setError("Error fetching balance. Please try again.");
          console.error("Error fetching balance:", error);
        }
      }
    };

    fetchUserBalance();
  }, [user]);

  const createRequest = async (
    astrologerId,
    type,
    price,
    astrologerPhoneNumber
  ) => {
    if (!user) {
      navigate("/login"); // Redirect to login if not logged in
      return;
    }
    try {
      setModalVisible(true);
      const response = await api.post("/request", {
        user_id: user.id,
        pandit_astrologer_id: astrologerId,
        request_type: type,
        status: "pending",
      });

      const requestId = response.data.requestId;
      pollRequestStatus(
        requestId,
        type,
        astrologerId,
        price,
        astrologerPhoneNumber
      );
    } catch (error) {
      console.error("Failed to create request:", error);
      setError("Failed to create request.");
      setModalVisible(false);
    }
  };

  const pollRequestStatus = (
    requestId,
    type,
    astrologerId,
    price,
    astrologerPhoneNumber
  ) => {
    console.log("Polling request status", requestId);
    const intervalId = setInterval(async () => {
      try {
        const response = await api.get(`/request/status/${requestId}`);
        console.log("Request status:", response.data.status);
        setRequestStatus(response.data.status);

        if (response.data.status !== "pending") {
          clearInterval(intervalId);
          setModalVisible(false);
          console.log(response.data.status);
          if (response.data.status === "accepted") {
            if (type === "chat") {
              navigate(`/chat/${astrologerId}/${price}/${requestId}`);
            } else if (type === "voice" || type === "video") {
              initiateCall(
                astrologerId,
                type,
                astrologerPhoneNumber,
                requestId
              );
            }
          }
        }
      } catch (error) {
        console.error("Failed to fetch request status:", error);
        clearInterval(intervalId);
        setModalVisible(false);
      }
    }, 5000); // Poll every 5 seconds
  };

  const initiateCall = async (
    astrologerId,
    type,
    astrologerPhoneNumber,
    requestId
  ) => {
    try {
      const response = await api.post("/call/initiate", {
        callerId: user.id,
        receiverId: astrologerId,
        type,
        callerPhoneNumber: user.mobile,
        receiverPhoneNumber: astrologerPhoneNumber,
        request_id: requestId,
      });

      if (response.data.success) {
        alert("Call initiated successfully");
        if (type === "voice") {
          navigate(`/voicecall/${response.data.call.twilioCallSid}`);
        } else if (type === "video") {
          navigate(
            `/videocall/${response.data.call.roomName}/${response.data.call.callerToken}`
          );
        }
      } else {
        alert("Error", response.data.message);
      }
    } catch (error) {
      console.error("Failed to initiate call:", error);
      alert("Failed to initiate call");
    }
  };

  const handleChat = (astrologerId, price) => {
    if (!user) {
      navigate("/login"); // Redirect to login if not logged in
      return;
    }
    const b = parseFloat(balance);
    const p = parseFloat(price);

    // Compare and proceed with the logic
    if (b >= p) {
      createRequest(astrologerId, "chat", price);
    } else {
      alert("Insufficient balance! Please recharge.");
      navigate("/recharge");
    }
  };

  const handleVoiceCall = (astrologerId, mobile, price) => {
    if (!user) {
      navigate("/login"); // Redirect to login if not logged in
      return;
    }
    const b = parseFloat(balance);
    const p = parseFloat(price);
    console.log(b >= p, b, p);
    if (b >= p) {
      createRequest(astrologerId, "voice", price, mobile);
    } else {
      alert("Insufficient balance! Please recharge.");
      navigate("/recharge");
    }
  };

  const handleVideoCall = (astrologerId, price) => {
    if (!user) {
      navigate("/login"); // Redirect to login if not logged in
      return;
    }
    const b = parseFloat(balance);
    const p = parseFloat(price);
    console.log(b >= p, b, p);
    // Compare and proceed with the logic
    if (b >= p) {
      createRequest(astrologerId, "video", price);
    } else {
      alert("Insufficient balance! Please recharge.");
      navigate("/recharge");
    }
  };

  if (loading) return <div className="loading-message">Loading...</div>;
  if (error) return <div className="error-message">{error}</div>;
  if (!panditData) return <div className="no-data-message">No Pandit Data</div>;

  return (
    <>
      <div className="pandit-profile">
        <div className="profile-container">
          <div className="profile-row">
            <div className="profile-image-column">
              <img
                src={`${panditData.profileImage}`}
                alt={panditData.name || "Astrologer"}
                width={300}
                height={300}
                className="profile-image"
              />
            </div>
            <div className="profile-details-column">
              <div className="profile-details">
                <h1 className="profile-name">{panditData.name}</h1>
                <p className="profile-description">
                  {panditData.name}, an Indian Pandit, offers expert guidance
                  aimed at fostering stability and clarity in life. Through
                  straightforward remedies and personalized attention, he
                  empowers clients, ensuring their satisfaction and trust in his
                  solutions.
                </p>
                <div className="action-buttons-row">
                  <div className="action-button-column">
                    <button
                      className="action-button chat_button"
                      onClick={() =>
                        handleChat(panditData.id, panditData.price)
                      }
                    >
                      <div className="action-button-content">
                        <div className="action-button-text">Chat</div>
                      </div>
                    </button>
                  </div>
                  <div className="action-button-column">
                    <button
                      className="action-button voice-call-button"
                      onClick={() =>
                        handleVoiceCall(
                          panditData.id,
                          panditData.mobile,
                          panditData.price
                        )
                      }
                    >
                      <div className="action-button-content">
                        <div className="action-button-text">Call</div>
                      </div>
                    </button>
                  </div>
                  <div className="action-button-column">
                    <button
                      className="action-button video-call-button"
                      onClick={() =>
                        handleVideoCall(panditData.id, panditData.price)
                      }
                    >
                      <div className="action-button-content">
                        <div className="action-button-text">Video Call</div>
                      </div>
                    </button>
                  </div>
                </div>
                <Link to="/enquiryform">
                  <button className="enquire-button">Enquire Now</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {modalVisible && (
        <div className="loader-overlay">
          <div className="loader-spinner">
            <img src={loader} alt="Loading" />
          </div>
        </div>
      )}
    </>
  );
};

export default PanditProfile;
