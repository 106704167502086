import React from "react";
import "../../styles/kalsharpdosh.css";

// import mahamritunjayjaapimg from "../Assets/maha-mritunejay-image.jpeg";
// import panditimg from "../Assets/panditji.png";
// import { Link } from "react-router-dom";
import Poojapage from "../poojapage/poojapage";
import Poojapanditprofile from "../onlinepuja/poojapanditprofile";

const Shrimahamritunejayjaap = () => {
  return (
    <>
      {/* <div className="category_heading">
        <div className="container">
          <div className="row">
            <div className="col-sm-5">
              <div className="product_image">
                <img src={mahamritunjayjaapimg} alt="img" />
              </div>
            </div>
            <div className="col-sm-5">
              <h1 className="product_content">Shri Maha Mritunjay jaap</h1>
              <div className="product_name">
                <p>
                  Shri Maha Mritunjay jaap se rogiyon ki raksha aur swasthya ka
                  labh hota hai.
                </p>
              </div>

              <div className="timeshedule_section">
                <span>09 days left</span>
              </div>

              <div className="booknow-btn">
              <Link to="/login"> <button className="booknoww">Book Now</button> </Link>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <Poojapage />

      <div className="about_according">
        <div className="container">
          <div className="row">
            <div className="col-sm-6">
              {/* <div className="details_content">
                <div className="pandit_profile">
                  <div className="profile_pic">
                    <img src={panditimg} alt="" />
                  </div>
                  <div className="profile_details">
                    <span className="profile_name">NAME</span>
                    <span className="profile_skill">Vedic Guru</span>
                  </div>
                </div>

                <p className="profile_bio">
                  “NAME”, a renowned Indian Vedic Astrologer, offers expert
                  guidance to help you achieve a stable and clear life. With his
                  deep knowledge of Vedic astrology, “NAME” provides
                  personalized attention and simple yet effective remedies
                  tailored to your unique needs.
                </p>
              </div> */}

              <Poojapanditprofile />
            </div>

            <div className="col-sm-6">
              <div className="next_section">
                <h2>What are the benefits?</h2>
                <div className="next_details">
                  <ul>
                    <li>
                      Protects against premature death and grants longevity and
                      health.
                    </li>
                    <li>
                      Helps in overcoming illnesses, Reduces stress and anxiety
                      and Fosters inner calm.
                    </li>
                    <li>Guards against negative energies and evil spirits.</li>
                    <li>Removes negative energies.</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-sm-6">
              <div className="next_section">
                <h2>How will it happen?</h2>
                <div className="next_details">
                  <ul>
                    <li>Book the Pooja.</li>
                    <li>
                      Schedule the Pooja. Provide your name and surname/Gotra
                      for the Sankalp, which the Pandit will take.
                    </li>
                    <li>
                      Have a Dupatta, Handkerchief, or Towel ready to cover your
                      head at the beginning of the Pooja.
                    </li>
                    <li>
                      Listen attentively to the Pooja and seek blessings with
                      devotion.
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-sm-6">
              <div className="next_section">
                <h2>About Maha Mrityunjaya Mantra :</h2>
                <div className="next_details">
                  <ul>
                    <li>
                      Protection and Longevity: The Maha Mrityunjaya Mantra
                      safeguards against premature death and grants longevity
                      and robust health.
                    </li>
                    <li>
                      Healing and Calm: It helps in overcoming illnesses,
                      reduces stress and anxiety, and fosters a deep sense of
                      inner calm.
                    </li>
                    <li>
                      Purification and Shielding: The mantra guards against
                      negative energies and evil spirits, removing any lingering
                      negative influences and purifying one's environment.
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-sm-6">
              <div className="next_section">
                <h2>What should you do after Pooja to get maximum benefits?</h2>
                <div className="next_details">
                  <ul>
                    <li>
                      Continue chanting the Maha Mrityunjaya Mantra regularly.
                      Even a few repetitions each day can help maintain the
                      protective and healing vibrations.
                    </li>
                    <li>
                      Wearing specific protective amulets, talismans, or
                      gemstones can help in sustaining the benefits of the Maha
                      Mrityunjaya Pooja.
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-sm-6">
              <div className="next_section">
                <h2>Why Book with Prabhu Pooja?</h2>
                <div className="next_details">
                  <ul>
                    <li>
                      Prabhu Pooja is India's largest Devotion tech platform,
                      offering access to top astrologers and Pandits.
                    </li>
                    <li>
                      Our Pooja services feature the best Pandits on the
                      platform to ensure you receive the maximum benefits.
                    </li>
                    <li>
                      This Pooja includes family participation and covers
                      Dakshina as well.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Shrimahamritunejayjaap;
