import React, { useState } from "react";
import api from "../Axios/api";
import { useNavigate, useLocation } from "react-router-dom";
import Swal from "sweetalert2";

const Booknowform = () => {
  const [pujaDate, setPujaDate] = useState("");
  const [dob, setDob] = useState("");
  const [rashi, setRashi] = useState("");
  const [goutra, setGoutra] = useState("");
  const [orderId, setOrderId] = useState("");
  const [errors, setErrors] = useState({});

  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("users")) || {};
  const location = useLocation();
  const { templeId, price } = location.state || {};
  console.log(location);

  const validateForm = () => {
    const newErrors = {};
    if (!pujaDate) newErrors.pujaDate = "Please select a desired date.";
    if (!dob) newErrors.dob = "Please enter your date of birth.";
    if (!rashi) newErrors.rashi = "Please select your Rashi.";
    if (!goutra) newErrors.goutra = "Please enter your Gotram.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handlePayment = async (price) => {
    if (!validateForm()) return;

    if (!user || !user.id) {
      Swal.fire({
        icon: "warning",
        title: "Not Logged In",
        text: "Please log in to book the temple.",
        confirmButtonText: "Go to Login",
      }).then(() => navigate("/login"));
      return;
    }

    try {
      const order = await api.post("/payment/create-payment", {
        amount: price,
        currency: "INR",
        user_id: user.id,
        puja: "temple",
      });

      const options = {
        key: "rzp_test_v7wpWtGuqCofvq",
        amount: order.data.data.amount,
        currency: "INR",
        name: user.name || "Guest",
        description: "Temple Session Booking",
        order_id: order.data.data.id,
        handler: async function (response) {
          try {
            const verifyResponse = await api.post("/payment/verify-payment", {
              razorpay_order_id: response.razorpay_order_id,
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_signature: response.razorpay_signature,
            });

            if (verifyResponse.data.success) {
              // Only after verification, set the order ID and proceed to booking
              setOrderId(response.razorpay_payment_id);
              await handleSubmit(response.razorpay_payment_id); // Pass the order ID to handleSubmit
            } else {
              Swal.fire({
                icon: "error",
                title: "Payment Failed",
                text: "Payment verification failed. Please try again.",
              });
            }
          } catch (error) {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: "Verification failed. Please contact support.",
            });
          }
        },
        prefill: {
          email: user.email || "",
          contact: user.mobile || "",
        },
        theme: {
          color: "#3399cc",
        },
        method: {
          upi: true,
          qr: true,
        },
      };

      const rzp1 = new window.Razorpay(options);
      rzp1.open();

      rzp1.on("payment.failed", function (response) {
        Swal.fire({
          icon: "error",
          title: "Payment Error",
          text: `Error: ${response.error.code} | ${response.error.description}`,
        });
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Payment Failed",
        text: "Unable to initiate payment. Please try again.",
      });
    }
  };

  const handleSubmit = async (orderId) => {
    if (!pujaDate || !dob || !rashi || !goutra || !orderId) {
      Swal.fire({
        icon: "warning",
        title: "Incomplete Form",
        text: "Please fill in all the required fields.",
      });
      return;
    }

    try {
      const response = await api.post("/temple/booking", {
        userId: user.id,
        puja_date: pujaDate,
        dob: dob,
        rashi: rashi,
        goutra: goutra,
        order_id: orderId, // Use the order ID passed here
        temple_id: templeId,
      });

      if (response.data.success) {
        Swal.fire({
          icon: "success",
          title: "Booking Successful",
          text: "Temple booking created successfully!",
        }).then(() => navigate("/temple"));
      } else {
        Swal.fire({
          icon: "error",
          title: "Booking Failed",
          text: response.data.message,
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred while booking. Please try again.",
      });
    }
  };

  console.log(orderId, "hell00o");

  return (
    <div className="addUser">
      <h3>Book Temple</h3>
      <form className="addUserForm" onSubmit={(e) => e.preventDefault()}>
        <div className="inputGroup">
          <label htmlFor="pujaDate">Desired Date (Atleast 3 days later)</label>
          <input
            type="date"
            id="pujaDate"
            required
            autoComplete="off"
            min={
              new Date(new Date().setDate(new Date().getDate() + 1))
                .toISOString()
                .split("T")[0]
            }
            onChange={(e) => setPujaDate(e.target.value)}
          />
          {errors.pujaDate && (
            <span className="bookerror">{errors.pujaDate}</span>
          )}

          <label htmlFor="name">Name:</label>
          <input
            type="text"
            id="name"
            autoComplete="off"
            placeholder="Name"
            required
            value={user.name || ""}
            disabled
          />

          <label htmlFor="phone">Number:</label>
          <input
            type="text"
            id="phone"
            required
            autoComplete="off"
            placeholder="Phone Number"
            value={user.mobile || ""}
            disabled
          />

          <label htmlFor="dob">Date of Birth:</label>
          <input
            type="date"
            id="dob"
            required
            autoComplete="off"
            value={dob}
            max={
              new Date(new Date().setDate(new Date().getDate() - 1))
                .toISOString()
                .split("T")[0]
            }
            onChange={(e) => setDob(e.target.value)}
          />
          {errors.dob && <span className="bookerror">{errors.dob}</span>}

          <label htmlFor="rashi">Rashi / Zodiac Sign:</label>
          <select
            id="rashi"
            name="rashi"
            className="rashi_field"
            value={rashi}
            onChange={(e) => setRashi(e.target.value)}
          >
            <option value="">Select Rashi</option>
            <option value="Mesha">Mesha (Aries)</option>
            <option value="Vrishabha">Vrishabha (Taurus)</option>
            <option value="Mithuna">Mithuna (Gemini)</option>
            <option value="Karka">Karka (Cancer)</option>
            <option value="Simha">Simha (Leo)</option>
            <option value="Kanya">Kanya (Virgo)</option>
            <option value="Tula">Tula (Libra)</option>
            <option value="Vrishchika">Vrishchika (Scorpio)</option>
            <option value="Dhanu">Dhanu (Sagittarius)</option>
            <option value="Makara">Makara (Capricorn)</option>
            <option value="Kumbha">Kumbha (Aquarius)</option>
            <option value="Meena">Meena (Pisces)</option>
          </select>
          {errors.rashi && <span className="bookerror">{errors.rashi}</span>}

          <label htmlFor="goutra">Gotram:</label>
          <input
            type="text"
            id="goutra"
            autoComplete="off"
            placeholder="Gotram"
            value={goutra}
            onChange={(e) => setGoutra(e.target.value)}
          />
          {errors.goutra && <span className="bookerror">{errors.goutra}</span>}

          <button
            type="button"
            className="btn btn-success"
            onClick={() => handlePayment(price)}
          >
            Book Now
          </button>
        </div>
      </form>
    </div>
  );
};

export default Booknowform;
