import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
// import panditimg from "../Assets/panditji.png";
import api from "../Axios/api";

function Poojapanditprofile() {
  const [pandit, setPandit] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { id } = useParams();
  // console.log("here is the id",id)
  useEffect(() => {
    const fetchPanditDetails = async () => {
      try {
        const response = await api.get(`/user/onlinePuja/${id}/pandits`);
        // console.log("here is the response data of pandit ",response.data.data[0])
        // console.log("here is the response data",response.data.data[0])
        setPandit(response.data.data[0]);
        setLoading(false);
      } catch (error) {
        setError("Failed to fetch Pandit details");
        setLoading(false);
      }
    };

    fetchPanditDetails();
  }, [id]);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  if (!pandit) {
    return <p>No Pandit found for this Pooja.</p>;
  }

  return (
    <div className="details_content">
      <div className="pandit_profile">
        <div className="profile_pic">
          <img src={pandit.profileImage} alt="" />
        </div>
        <div className="profile_details">
          <span className="profile_name">{pandit.name}</span>
          <span className="profile_skill">{pandit.skills}</span>
        </div>
      </div>

      <p className="profile_bio">
        “{pandit.name}” is a vedic expert with {pandit.experience} years of experience who specializes in
        relationship problems and adivses 100% sucessful remedies.
      </p>
    </div>
  );
}

export default Poojapanditprofile;
