import React, { useState, useEffect } from "react";
import api from "../Axios/api";
import StarRatings from "react-star-ratings";

const RatingsPopup = ({ id, onClose }) => {
  const [comments, setComments] = useState([]);

  useEffect(() => {
    fetchComments();
  }, [id]);

  const fetchComments = async () => {
    try {
      const response = await api.get(`/feedback/rating/${id}`);
      console.log(response.data.data.feedbacks);
      if (response.data.success) {
        setComments(response.data.data.feedbacks || []);
      } else {
        console.error("Failed to fetch comments");
      }
    } catch (error) {
      console.error("Error fetching comments:", error);
    }
  };

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <div className="popup-header">
          <h2>Ratings & Comments</h2>
          <button className="close-btn" onClick={onClose}>
            &times;
          </button>
        </div>
        <div className="ratings-comments-section">
          {comments.length > 0 ? (
            comments.map((comment, index) => {
              const rating = Number(comment.rating); // Ensure rating is a number
              return (
                <div key={index} className="comment-box">
                  <p>
                    <strong>{comment.name}:</strong> {comment.comment}
                  </p>
                  <StarRatings
                    rating={isNaN(rating) ? 0 : rating} // Handle NaN values
                    starRatedColor="gold"
                    starDimension="15px"
                    starSpacing="2px"
                    numberOfStars={5}
                  />
                </div>
              );
            })
          ) : (
            <p>No comments yet.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default RatingsPopup;
