import React from "react";
import manimg from "../Assets/astrology-man.jpg";
import "../../styles/astrology.css";
import { Link } from "react-router-dom";

const Astrologyprofileman = () => {
  return (
    <>
      <section className="section">
        <div className="container">
          <div className="astrologyprofile">
            <h1>Rajat Thakkar</h1>

            <div className="row astrologyprofile">
              <div className="col-sm-8">
                <div className="package-transport">
                  <div className="row">
                    <div className="col-sm-4">
                      <img src={manimg} alt="" />
                    </div>

                    <div className="col-sm-8">
                      <div className="row">
                        <div className="col-sm-12">
                          <h2 className="special_title"></h2>

                          <p className="point-consult">
                            <i
                              class="fa-solid fa-language"
                              style={{ marginRight: "5px" }}
                            ></i>{" "}
                            Hindi, English
                          </p>

                          <p className="point-consult">
                            <i
                              class="fa-solid fa-suitcase"
                              style={{ marginRight: "5px" }}
                            ></i>{" "}
                            10 Yrs
                          </p>

                          <p className="point-consult">
                            <i
                              class="fa-solid fa-rupee-sign"
                              style={{ marginRight: "5px" }}
                            ></i>{" "}
                            Starting From Rs.1100 / 30 minutes
                          </p>

                          <p>
                            Expert in Vedic Astrology, Medical Astrology, Face
                            Reading.
                          </p>
                        </div>
                      </div>
                      <hr />

                      <div className="profile_btn">
                        <div className="chat-btn-pandit">
                          <div className="chat-button">
                            <Link>
                              <button className="btn_chat btn_chat_online">
                                Call
                              </button>
                            </Link>
                          </div>
                        </div>

                        <div className="chat-btn-pandit">
                          <div className="chat-button">
                            <Link>
                              <button className="btn_chat btn_chat_online">
                                Chat
                              </button>
                            </Link>
                          </div>
                        </div>

                        <div className="chat-btn-pandit">
                          <div className="chat-button">
                            <Link>
                              <button className="btn_chat btn_chat_online">
                                Video Call
                              </button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="col-sm-4">
                <form action="" className="package_form">
                  <div
                    className="pbox"
                    style={{
                      background: "#e9a259",
                      padding: "5px 8px",
                      marginBottom: "5px",
                    }}
                  >
                    <h6 className="original-price">
                      <strong>Rs.1100</strong>
                    </h6>
                  </div>

                  <div className="form-group">
                    <input
                      type="hidden"
                      required="required"
                      className="input"
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="">
                      Specialist <span>*</span>
                    </label>
                    <select
                      name="temple"
                      id=""
                      required
                      className="form-control inputbox-line"
                    >
                      <option value="">
                        Kundali Matching - Rs.1100/30 minutes
                      </option>
                      <option value="">Face Reader - Rs.1100/30 minutes</option>
                      <option value="">
                        Vedic Astrologer - Rs.1100/30 minutes
                      </option>
                    </select>
                  </div>

                  <div className="form-group">
                    <label htmlFor="">How much time need?: *</label>
                    <select
                      name="temple"
                      id=""
                      required
                      className="form-control inputbox-line"
                    >
                      <option value="">--Select Hours *--</option>
                      <option value="">30 min</option>
                      <option value="">1 Hour</option>
                      <option value="">1/2 Hour</option>
                    </select>
                  </div>

                  <Link to="/checkout">
                    <button className="primary_btn yellow">Book Now</button>
                  </Link>
                </form>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Astrologyprofileman;
