import React, { useEffect, useState } from "react";
import "../../styles/pandit.css";
import { Link, useNavigate } from "react-router-dom";

import api from "../Axios/api";
import loader from "../Assets/loader.svg";

const Panditmuhuratprofile = () => {
  const [services, setServices] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [balance, setBalance] = useState(0);
  const [requestStatus, setRequestStatus] = useState(null);
  const user = JSON.parse(localStorage.getItem("users"));
  const navigate = useNavigate();
 const [isMember, setIsMember] = useState(false);
  const member = localStorage.getItem("member");
  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await api.get("/pandit/mahuratPandit");
        if (response.data.success) {
          setServices(response.data.data);
        } else {
          setError(response.data.message || "Failed to fetch services.");
        }
      } catch (error) {
        setError("Error fetching services. Please try again.");
        console.error("Error fetching services:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchServices();
  }, []);

  useEffect(() => {
    const fetchUserBalance = async () => {
      if (user && user.id) {
        try {
          const response = await api.get(`/users/balance/${user.id}`);
          if (response.data.success) {
            setBalance(response.data.balance);
          } else {
            setError(response.data.message || "Failed to fetch balance.");
          }
        } catch (error) {
          setError("Error fetching balance. Please try again.");
          console.error("Error fetching balance:", error);
        }
      }
    };

    fetchUserBalance();
  }, [user]);
  
   useEffect(() => {
      if (member) {
        try {
          const memberData = JSON.parse(member);
          if (memberData === true) {
            setIsMember(true);
          }
        } catch (error) {
          console.error("Error parsing member data:", error);
        }
      }
    }, [member]); 

  function slugify(text) {
    return text
      .toString()
      .toLowerCase()
      .replace(/\s+/g, "")
      .replace(/\-/g, "")
      .replace(/\s+/g, "-")
      .replace(/[^\w\-]+/g, "")
      .replace(/\-\-+/g, "-")
      .replace(/^-+/, "")
      .replace(/-+$/, "");
  }

  const createRequest = async (
    astrologerId,
    type,
    price,
    astrologerPhoneNumber
  ) => {
    if (!user) {
      navigate("/login");
      return;
    }
    try {
      setModalVisible(true);
      const response = await api.post("/request", {
        user_id: user.id,
        pandit_astrologer_id: astrologerId,
        request_type: type,
        status: "pending",
      });

      const requestId = response.data.requestId;
      pollRequestStatus(
        requestId,
        type,
        astrologerId,
        price,
        astrologerPhoneNumber
      );
    } catch (error) {
      console.error("Failed to create request:", error);
      setError("Failed to create request.");
      setModalVisible(false);
    }
  };

  const pollRequestStatus = (
    requestId,
    type,
    astrologerId,
    price,
    astrologerPhoneNumber
  ) => {
    console.log("in polrequest function", requestId);
    const intervalId = setInterval(async () => {
      try {
        const response = await api.get(`/request/status/${requestId}`);
        console.log("here is the status", response.data.status);
        setRequestStatus(response.data.status);

        if (response.data.status !== "pending") {
          clearInterval(intervalId);
          setModalVisible(false);
          console.log(response.data.status);
          if (response.data.status === "accepted") {
            if (type === "chat") {
              navigate(`/chat/${astrologerId}/${price}/${requestId}`);
            } else if (type === "voice" || type === "video") {
              initiateCall(
                astrologerId,
                type,
                astrologerPhoneNumber,
                requestId
              );
            }
          }
        }
      } catch (error) {
        console.error("Failed to fetch request status:", error);
        clearInterval(intervalId);
        setModalVisible(false);
      }
    }, 5000);
  };

  const initiateCall = async (
    astrologerId,
    type,
    astrologerPhoneNumber,
    requestId
  ) => {
    try {
      const response = await api.post("/call/initiate", {
        callerId: user.id,
        receiverId: astrologerId,
        type,
        callerPhoneNumber: user.mobile,
        receiverPhoneNumber: astrologerPhoneNumber,
        request_id: requestId,
      });

      if (response.data.success) {
        alert("Call initiated successfully");
        if (type === "voice") {
          navigate(`/voicecall/${response.data.call.twilioCallSid}`);
        } else if (type === "video") {
          navigate(
            `/videocall/${response.data.call.roomName}/${response.data.call.callerToken}`
          );
        }
      } else {
        alert("Error", response.data.message);
      }
    } catch (error) {
      console.error("Failed to initiate call:", error);
      alert("Failed to initiate call");
    }
  };
  const handleChat = (astrologerId, price) => {
    if (!user) {
      navigate("/signup");
      return;
    }
    const b = parseFloat(balance);  
    const p = parseFloat(price);      
    console.log(b>=p,b,p)
    
    if (b >= p||isMember) {
      createRequest(astrologerId, "chat", price);
    } else {
      alert("Insufficient balance! Please recharge.");
      navigate("/recharge");
    }

  };

  const handleVoiceCall = (astrologerId, mobile, price) => {
    if (!user) {
      navigate("/signup");
      return;
    }
    const b = parseFloat(balance);  
    const p = parseFloat(price);      
    console.log(b>=p,b,p)
    if (b >= p||isMember) {
      createRequest(astrologerId, "voice", price, mobile);
    } else {
      alert("Insufficient balance! Please recharge.");
      navigate("/recharge");
      
    }
  };

  const handleVideoCall = (astrologerId, price) => {
    if (!user) {
      navigate("/signup");
      return;
    }
    const b = parseFloat(balance);  
    const p = parseFloat(price);      
    console.log(b>=p,b,p)
    // Compare and proceed with the logic
    if (b >= p||isMember) {
      createRequest(astrologerId, "video", price);
    } else {
      alert("Insufficient balance! Please recharge.");
      navigate("/recharge");

    }
  };

  return (
    <>
      <section className="pandit_section muhurat_section">
        <div className="container">
          <form action="">
            <div className="pandit_baar">
              <div className="panditbaar">
                <h1 className="heading_chat_with_pandit">Talk to Pandit</h1>
              </div>
            </div>
          </form>

          <div className="best-pandit">
            <div className="row">
              {services.map((service, index) => (
                <div className="col-sm-4 pandit-section-start" key={index}>
                  <div className="pandit-box pandit_boxes2">
                    <div className="box-upper-details1">
                      <div className="pandit-details">
                        <div className="pandit-img">
                          <div className="pandit_image normal-border">
                            <Link
                              to={`/panditaboutprofile/${slugify(service.id)}`}
                            >
                              <img src={`${service.profileImage}`} alt="" />
                            </Link>
                          </div>
                          <div className="star-rate">
                            <i class="fa-solid fa-star"></i>
                            <i class="fa-solid fa-star"></i>
                            <i class="fa-solid fa-star"></i>
                            <i class="fa-solid fa-star"></i>
                          </div>
                        </div>

                        <div className="pandit-bio">
                          <div className="pandit-name">{service.name}</div>
                          <div className="pandit-designation light-clr">
                            <span>{service.skills}</span>
                          </div>
                          <div className="pandit-language light-clr">
                            <span>{service.language}</span>
                          </div>
                          <div className="pandit-experience light-clr">
                            Exp: {service.experience}
                          </div>
                          <div className="pandit-price-chat">
                            <span className="redBold">
                              {service.price} ₹ / 15 min
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="pandit_all_btn">
                        <div className="chat-btn-pandit">
                          <div className="chat-button">
                            <Link>
                              <button
                                className="btn_chat btn_chat_online"
                                onClick={() =>
                                  handleVoiceCall(
                                    service.id,
                                    service.mobile,
                                    service.price
                                  )
                                }
                              >
                                Call
                              </button>
                            </Link>
                          </div>
                        </div>

                        <div className="chat-btn-pandit">
                          <div className="chat-button">
                            <Link>
                              <button
                                className="btn_chat btn_chat_online"
                                onClick={() =>
                                  handleChat(service.id, service.price)
                                }
                              >
                                Chat
                              </button>
                            </Link>
                          </div>
                        </div>
                        <div className="chat-button">
                          <Link>
                            <button
                              className="btn_chat btn_chat_online"
                              onClick={() =>
                                handleVideoCall(service.id, service.price)
                              }
                            >
                              Video Call
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      {modalVisible && (
        <div className="loader">
          <div className="spinner">
            <img src={loader} alt="" />
          </div>
        </div>
      )}
    </>
  );
};

export default Panditmuhuratprofile;
