// import React, { useState } from "react";
// import "../../styles/signup.css";
// import { Link, useNavigate } from "react-router-dom";
// import api from "../Axios/api";
// import { toast, ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

// const Signup = () => {
//   const [name, setName] = useState("");
//   const [mobile, setMobile] = useState("");
//   const [email, setEmail] = useState("");
//   const [city, setCity] = useState("");
//   const [role, setRole] = useState("0");
//   const [errorMessage, setErrorMessage] = useState("");
//   const [nameError, setNameError] = useState("");
//   const [numberError, setNumberError] = useState("");
//   const [emailError, setEmailError] = useState("");
//   const [cityError, setCityError] = useState("");

//   const navigate = useNavigate();

//   const validateName = () => {
//     if (name.trim() === "") {
//       setNameError("Name is required");
//     } else {
//       setNameError("");
//     }
//   };

//   const validateNumber = () => {
//     const regex = /^\d{10}$/;
//     if (!regex.test(mobile)) {
//       setNumberError("Please enter a valid 10-digit mobile number");
//     } else {
//       setNumberError("");
//     }
//   };

//   const validateEmail = () => {
//     const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//     if (!regex.test(email)) {
//       setEmailError("Please enter a valid email address");
//     } else {
//       setEmailError("");
//     }
//   };

//   const validateCity = () => {
//     if (city.trim() === "") {
//       setCityError("City is required");
//     } else {
//       setCityError("");
//     }
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     validateName();
//     validateNumber();
//     validateEmail();
//     validateCity();

//     if (!nameError && !numberError && !emailError && !cityError) {
//       try {
//         const response = await api.post("users/register", {
//           name,
//           mobile,
//           email,
//           city,
//           role,
//         });

//         console.log("Registration success:", response.data);

//         if (response.data.token) {
//           localStorage.setItem("token", JSON.stringify(response.data.token));
//           localStorage.setItem("users", JSON.stringify(response.data.user));
//         localStorage.setItem("member" ,JSON.stringify(response.data.member));

//           localStorage.setItem("isVerified", "true");

//           // Emit a custom event to notify that the user has logged in
//           window.dispatchEvent(new Event("loginStatusChanged"));

//           // Navigate based on the role
//           if (role === "0") {
//             navigate("/");
//           } else if (role === "1") {
//             navigate("/panditform");
//           }

//           toast.success("Signup successfully!", {
//             position: "top-center",
//             toastStyle: { height: "50px" },
//           });
//         }
//       } catch (error) {
//         console.error("Registration failed:", error);
//         if (
//           error.response &&
//           error.response.data &&
//           error.response.data.message
//         ) {
//           setErrorMessage(error.response.data.message);
//         } else {
//           setErrorMessage("Registration failed. Please try again later.");
//         }
//       }
//     }
//   };

//   return (
//     <div className="addUser">
//       <h3>Sign Up</h3>
//       <form className="addUserForm" onSubmit={handleSubmit}>
//         <div className="inputGroup">
//           <label htmlFor="name">Name:</label>
//           <input
//             type="text"
//             id="name"
//             autoComplete="off"
//             placeholder="Enter your name"
//             value={name}
//             onChange={(e) => setName(e.target.value)}
//             onBlur={validateName}
//           />
//           {nameError && <p className="error">{nameError}</p>}

//           <label htmlFor="number">Number:</label>
//           <input
//             type="text"
//             id="number"
//             autoComplete="off"
//             placeholder="Enter your Mobile Number"
//             value={mobile}
//             onChange={(e) => setMobile(e.target.value)}
//             onBlur={validateNumber}
//           />
//           {numberError && <p className="error">{numberError}</p>}

//           <label htmlFor="email">Email:</label>
//           <input
//             type="email"
//             id="email"
//             autoComplete="off"
//             placeholder="Enter your Email"
//             value={email}
//             onChange={(e) => setEmail(e.target.value)}
//             onBlur={validateEmail}
//           />
//           {emailError && <p className="error">{emailError}</p>}

//           <label htmlFor="city">City:</label>
//           <input
//             type="text"
//             id="city"
//             autoComplete="off"
//             placeholder="Enter your City"
//             value={city}
//             onChange={(e) => setCity(e.target.value)}
//             onBlur={validateCity}
//           />
//           {cityError && <p className="error">{cityError}</p>}

//           <label htmlFor="role">Role:</label>
//           <select
//             id="role"
//             style={{ padding: "10px" }}
//             className="role_select"
//             value={role}
//             onChange={(e) => setRole(e.target.value)}
//           >
//             <option value="0">Devotee</option>
//             <option value="1">Priest</option>
//           </select>

//           <button type="submit" className="btn btn-success">
//             Sign Up
//           </button>
//         </div>
//         {errorMessage && <p>{errorMessage}</p>}
//       </form>
//       <div className="login">
//         <p>Already have an Account? </p>
//         <Link to="/login" className="btn btn-primary">
//           Login
//         </Link>
//       </div>
//       <ToastContainer />
//     </div>
//   );
// };

// export default Signup;
import React, { useState } from "react";
import "../../styles/signup.css";
import { Link, useNavigate } from "react-router-dom";
import api from "../Axios/api";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Signup = () => {
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [city, setCity] = useState("");
  const [role, setRole] = useState("0");
  const [profileImage, setProfileImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [nameError, setNameError] = useState("");
  const [numberError, setNumberError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [cityError, setCityError] = useState("");

  const navigate = useNavigate();

  const validateName = () => {
    if (name.trim() === "") {
      setNameError("Name is required");
    } else {
      setNameError("");
    }
  };

  const validateNumber = () => {
    const regex = /^\d{10}$/;
    if (!regex.test(mobile)) {
      setNumberError("Please enter a valid 10-digit mobile number");
    } else {
      setNumberError("");
    }
  };

  const validateEmail = () => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!regex.test(email)) {
      setEmailError("Please enter a valid email address");
    } else {
      setEmailError("");
    }
  };

  const validateCity = () => {
    if (city.trim() === "") {
      setCityError("City is required");
    } else {
      setCityError("");
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfileImage(file);
      setPreviewImage(URL.createObjectURL(file));
    }
  };

  const handleImageClick = () => {
    // Trigger file input click when the profile image is clicked
    document.getElementById("profileImage").click();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    validateName();
    validateNumber();
    validateEmail();
    validateCity();

    if (!nameError && !numberError && !emailError && !cityError) {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("mobile", mobile);
      formData.append("email", email);
      formData.append("city", city);
      formData.append("role", role);
      if (profileImage) {
        formData.append("image", profileImage);
      }

      try {
        const response = await api.post("/users/register", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        console.log("Registration success:", response.data);

        if (response.data.token) {
          localStorage.setItem("token", JSON.stringify(response.data.token));
          localStorage.setItem("users", JSON.stringify(response.data.user));
          localStorage.setItem("member", JSON.stringify(response.data.member));
          localStorage.setItem("image", response.data.image);
          localStorage.setItem("isVerified", "true");

          window.dispatchEvent(new Event("loginStatusChanged"));

          if (role === "0") {
            navigate("/");
          } else if (role === "1") {
            navigate("/panditform");
          }

          toast.success("Signup successfully!", {
            position: "top-center",
            toastStyle: { height: "50px" },
          });
        }
      } catch (error) {
        console.error("Registration failed:", error);
        console.error(
          "Registration failed:",
          error.response ? error.response.data : error.message
        );

        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          setErrorMessage(error.response.data.message);
        } else {
          setErrorMessage("Registration failed. Please try again later.");
        }
      }
    }
  };

  return (
    <div className="addUser">
      <h3>Sign Up</h3>
      <form className="addUserForm" onSubmit={handleSubmit}>
        <div
          onClick={handleImageClick}
          style={{
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          {previewImage ? (
            <img
              src={previewImage}
              alt="Profile Preview"
              style={{
                width: "100px",
                height: "100px",
                borderRadius: "50%",
                objectFit: "cover",
              }}
            />
          ) : (
            <div
              style={{
                width: "100px",
                height: "100px",
                marginTop: "10px",
                borderRadius: "50%",
                backgroundColor: "#e0e0e0",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                fontSize: "18px",
                color: "#888",
              }}
            >
              Profile Picture
            </div>
          )}
        </div>

        <input
          type="file"
          id="profileImage"
          accept="image/*"
          style={{ display: "none" }}
          onChange={handleImageChange}
        />

        {/* Other Form Fields */}
        <div className="inputGroup">
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            id="name"
            autoComplete="off"
            placeholder="Enter your name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            onBlur={validateName}
          />
          {nameError && <p className="error">{nameError}</p>}

          <label htmlFor="number">Number:</label>
          <input
            type="text"
            id="number"
            autoComplete="off"
            placeholder="Enter your Mobile Number"
            value={mobile}
            onChange={(e) => setMobile(e.target.value)}
            onBlur={validateNumber}
          />
          {numberError && <p className="error">{numberError}</p>}

          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            autoComplete="off"
            placeholder="Enter your Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onBlur={validateEmail}
          />
          {emailError && <p className="error">{emailError}</p>}

          <label htmlFor="city">City:</label>
          <input
            type="text"
            id="city"
            autoComplete="off"
            placeholder="Enter your City"
            value={city}
            onChange={(e) => setCity(e.target.value)}
            onBlur={validateCity}
          />
          {cityError && <p className="error">{cityError}</p>}

          <label htmlFor="role">Role:</label>
          <select
            id="role"
            style={{ padding: "10px" }}
            className="role_select"
            value={role}
            onChange={(e) => setRole(e.target.value)}
          >
            <option value="0">Devotee</option>
            <option value="1">Pandit</option>
          </select>

          <button type="submit" className="btn btn-success">
            Sign Up
          </button>
        </div>
        {errorMessage && <p>{errorMessage}</p>}
      </form>
      <div className="login">
        <p>Already have an Account? </p>
        <Link to="/login" className="btn btn-primary">
          Login
        </Link>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Signup;
