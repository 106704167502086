import React, { useEffect, useState } from "react";
import "../../styles/checkout.css";
import { useNavigate ,useLocation} from "react-router-dom";
import api from "../Axios/api"; 
import Swal from "sweetalert2";
// import { Link } from "react-router-dom";

const Checkout = () => {
  const location = useLocation();
  const { productId, quantity, totalPrice, user,booking } = location.state || {};
  console.log(location.state);
  
  const [formValues, setFormValues] = useState({
    name: "",
    lastName: "",
    email: "",
    mobile: "",
    address: "",
    country: "",
    state: "",
    city: "",
    postalCode: "",
  });
  const navigate = useNavigate();
 
  console.log(user , "iser")
  useEffect(() => {
    if (user) {
      setFormValues(user);
    } else {
      navigate("/login");
    }
  }, []);

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  const validate = () => {
    let newErrors = {};

    if (!formValues.name) newErrors.name = "First name is required.";
    // if (!formValues.lastName) newErrors.lastName = "Last name is required.";
    if (!formValues.email) {
      newErrors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(formValues.email)) {
      newErrors.email = "Email address is invalid.";
    }
    if (!formValues.mobile) {
      newErrors.mobile = "Phone mobile is required.";
    } else if (formValues.mobile.length !== 10) {
      newErrors.mobile = "Phone mobile must be 10 digits.";
    }
    if (!formValues.address) newErrors.address = "Address is required.";
    if (!formValues.country) newErrors.country = "Country is required.";
    if (!formValues.city) newErrors.city = "City is required.";
    if (!formValues.state) newErrors.state = "State is required.";
    // if (!formValues.postalcode) newErrors.postalcode = "PostalCode is required.";
    if (!formValues.postalCode)
      newErrors.postalCode = "PostalCode is required.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
   
  };
  const handlePayment = async () => {
    if (!user) {
      navigate("/login");
      return;
    }
  
    // Step 1: Validate the form
    if (!validate()) return;
  
    try {
      // Step 2: Update user details
      const updateResponse = await api.put(`/users/update/${user.id}`, formValues);
      if (!updateResponse.data.success) {
        Swal.fire("Error", "Failed to update user details.", "error");
        return;
      }
      Swal.fire("Success", "User details updated successfully.", "success");
  
      // Step 3: Create payment
     
      const paymentResponse = await api.post("/payment/create-payment", {
        amount: totalPrice,
        currency: "INR",
        user_id: user.id,
        puja: "order",
      });
  
      const { id: orderId, amount } = paymentResponse.data.data;
  
      // Razorpay options
      const options = {
        key: "rzp_test_v7wpWtGuqCofvq", // Replace with your Razorpay API key
        amount: amount,
        currency: "INR",
        name: "Your Store Name",
        description: "Product Purchase",
        order_id: orderId,
        handler: async function (response) {
          try {
            // Step 4: Verify payment
            const verifyResponse = await api.post("/payment/verify-payment", {
              razorpay_order_id: response.razorpay_order_id,
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_signature: response.razorpay_signature,
            });
  
            if (verifyResponse.data.success) {
              // Step 5: Create order
              await api.post("/orders/create", {
                productId: productId,
                userId: user.id,
                quantity: quantity,
                totalPrice: totalPrice,
                booking:booking
              });
              Swal.fire("Payment and Order Successful!", "", "success");
              navigate("/ecommerce");
            } else {
              Swal.fire("Error", "Payment verification failed.", "error");
            }
          } catch (error) {
            console.error("Verification or order creation failed:", error);
            Swal.fire("Payment or Order creation failed", "", "error");
          }
        },
        prefill: {
          email: user.email,
          contact: user.mobile,
        },
        theme: {
          color: "#3399cc",
        },
        method: {
          upi: true,
          qr: true,
        },
      };
  
      const rzp1 = new window.Razorpay(options);
      rzp1.open();
  
      // Handle payment failure
      rzp1.on("payment.failed", function (response) {
        Swal.fire(`Error: ${response.error.description}`, "", "error");
      });
    } catch (error) {
      console.error("Error during payment process:", error);
      Swal.fire("Error", "An error occurred during payment.", "error");
    }
  };
  

  return (
    <>
      <section style={{ marginBottom: "1.5rem" }}>
        <div className="container">
          <div className="checkout">
            <h1>Billing Details</h1>

            <form  className="checkout-form">
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <input
                      type="text"
                      name="name"
                      placeholder="First name"
                      className="form-control"
                      value={formValues.name}
                      onChange={handleChange}
                    />
                    {errors.name && (
                      <div className="error">{errors.name}</div>
                    )}
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <input
                      type="text"
                      name="lastName"
                      placeholder="Last name"
                      className="form-control"
                      value={formValues.lastName}
                      onChange={handleChange}
                    />
                    {/* {errors.lastName && <div className="error">{errors.lastName}</div>} */}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <input
                      type="email"
                      name="email"
                      placeholder="Email"
                      className="form-control"
                      value={formValues.email}
                      onChange={handleChange}
                    />
                    {errors.email && (
                      <div className="error">{errors.email}</div>
                    )}
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <input
                      type="tel"
                      name="mobile"
                      placeholder="mobile"
                      className="form-control"
                      value={formValues.mobile}
                      onChange={handleChange}
                    />
                    {errors.mobile && (
                      <div className="error">{errors.mobile}</div>
                    )}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-12">
                  <div className="form-group">
                    <input
                      type="text"
                      name="address"
                      placeholder="Address"
                      className="form-control"
                      value={formValues.address}
                      onChange={handleChange}
                    />
                    {errors.address && (
                      <div className="error">{errors.address}</div>
                    )}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <input
                      type="text"
                      name="city"
                      placeholder="City *"
                      className="form-control"
                      value={formValues.city}
                      onChange={handleChange}
                    />
                    {errors.city && <div className="error">{errors.city}</div>}
                  </div>
                </div>

                <div className="col-sm-6">
                  <div className="form-group">
                    <input
                      type="tel"
                      name="postalCode"
                      placeholder="Postal code"
                      className="form-control"
                      value={formValues.postalCode}
                      onChange={handleChange}
                    />
                    {errors.postalCode && (
                      <div className="error">{errors.postalCode}</div>
                    )}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <input
                      type="text"
                      name="state"
                      placeholder="State *"
                      className="form-control"
                      value={formValues.state}
                      onChange={handleChange}
                    />
                    {errors.state && (
                      <div className="error">{errors.state}</div>
                    )}
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <input
                      type="text"
                      name="country"
                      placeholder="Country *"
                      className="form-control"
                      value={formValues.country}
                      onChange={handleChange}
                    />
                    {errors.country && (
                      <div className="error">{errors.country}</div>
                    )}
                  </div>
                </div>
              </div>

              <button type="submit" className="primary_btn"
               onClick={(e)=>{
                e.preventDefault()
                handlePayment()}}>
                Book Now
              </button>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default Checkout;
