import React, { useEffect, useState } from "react";
import "../../styles/productdetail.css";
import { Link, useParams, useNavigate } from "react-router-dom";
import api from "../Axios/api";
import Swal from "sweetalert2";

const Productdetails = () => {
  let { productId } = useParams();
  const [productData, setProductData] = useState({});
  const navigate = useNavigate();

  const [quantity, setQuantity] = useState(1);
  const user = JSON.parse(localStorage.getItem("users"));
  console.log(productId, "productId");

  const fetchProductData = async () => {
    const res = await api.get(`/products/get/${productId}`);
    console.log(res.data.data[0], "response");
    setProductData(res.data.data[0]);
  };

  useEffect(() => {
    fetchProductData();
  }, []);
  const handleBuyNow = () => {
    const totalPrice = quantity * productData.offerPrice || 0;

    if (!user) {
      // Redirect to login page if user is not logged in
      navigate("/login");
    } else {
      // Pass the necessary data to the checkout page
      navigate("/checkout", {
        state: {
          productId: productId,
          quantity: quantity,
          totalPrice: totalPrice,
          user: user,
          productData: productData,
        },
      });
    }
  };
  return (
    <>
      <section className="section">
        <div className="container">
          <h1 className="product_title">
            Prabhu Pooja | {productData.productName}
          </h1>

          <div className="row">
            <div className="col-sm-8">
              <div className="product-img mb-5">
                <Link>
                  <img src={productData.image} alt="" />
                </Link>
              </div>
              <div className="product">
                <div className="product-tabs">
                  <button className="product-link active">Details</button>
                </div>
              </div>
              <div className="product-content">
                <div className="product-pane active">
                  <div>
                    <span>Theme:</span>
                    <span> {productData.theme}</span>
                  </div>
                  <div>
                    <span>Brand:</span>
                    <span> {productData.brand}</span>
                  </div>
                  <div>
                    <span>Colour:</span>
                    <span> {productData.colour}</span>
                  </div>
                  <div>
                    <span>Style:</span>
                    <span> {productData.style}</span>
                  </div>
                  <div>
                    <span>Material:</span>
                    <span> {productData.material}</span>
                  </div>
                  <div>
                    <span>Special Feature:</span>
                    <span> {productData.specialFeature}</span>
                  </div>
                  <div>
                    <span>Number of Pieces:</span>
                    <span> {productData.noOfPieces}</span>
                  </div>
                  <div>
                    <span>Number of Items:</span>
                    <span> {productData.noOfItems}</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-4">
              <form action="" className="package-form">
                <div
                  className="product-box"
                  style={{
                    background: "rgb(248, 117, 69)",
                    color: "#fff",
                    padding: "8px 8px",
                    marginBottom: "5px",
                  }}
                >
                  <h6 className="product_current_price">
                    <strong style={{ textDecoration: "line-through" }}>
                      RS.{productData.price}
                    </strong>
                    <strong>
                      {" "}
                      RS.{quantity * productData.offerPrice || 0}
                    </strong>
                  </h6>
                </div>

                <div className="form-group">
                  <label htmlFor="">
                    Quantity: <span>*</span>
                  </label>
                  <input
                    type="number"
                    className="input"
                    required
                    min="1"
                    max="10"
                    defaultValue="1"
                    value={quantity}
                    onChange={(e) => {
                      const newQuantity = Math.max(
                        1,
                        Math.min(10, e.target.value)
                      );
                      setQuantity(newQuantity);
                    }}
                  />
                </div>

                <button
                  className="product-btn"
                  onClick={(e) => {
                    e.preventDefault();
                    handleBuyNow();
                  }}
                >
                  Buy Now
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Productdetails;
