import React from "react";
import { Link } from "react-router-dom";
import "../styles/footer.css";

import logo from "../Components/Assets/prabhupooja-logo.png";

import { FaFacebook } from "react-icons/fa";
//import { FaTwitter } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";

//import { FaLocationDot } from "react-icons/fa6";
//import { MdEmail } from "react-icons/md";
//import { MdCall } from "react-icons/md";

//import { MdKeyboardDoubleArrowRight } from "react-icons/md";

const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-sm-4">
            <div className="logo">
              <Link>
                <img src={logo} alt="" />
              </Link>
            </div>
            <p className="footer-para">
              Online Pooja and Astrology Services In today's digital age, the
              ancient practices of Hindu rituals and astrology have seamlessly
              integrated with technology to offer online Pooja and astrology
              services.
            </p>
          </div>

          <div className="col-sm-4">
            <div className="f_heading1">Quick Links</div>
            <div className="f_links">
              <li>
                <Link to="/">
                  <i class="fa-solid fa-angles-right"></i>
                  Home
                </Link>
              </li>

              <li>
                <Link to="/membership">
                  <i class="fa-solid fa-angles-right"></i>
                  Membership
                </Link>
              </li>

              <li>
                <Link to="/onlinepooja">
                  <i class="fa-solid fa-angles-right"></i>
                  Online Pooja
                </Link>
              </li>

              <li>
                <Link to="/prasaddelivery">
                  <i class="fa-solid fa-angles-right"></i>
                  Prasad Delivery
                </Link>
              </li>

              <li>
                <Link to="/astrology">
                  <i class="fa-solid fa-angles-right"></i>
                  Astrology
                </Link>
              </li>

              <li>
                <Link to="/ecommerce">
                  <i class="fa-solid fa-angles-right"></i>
                  E-Commerce
                </Link>
              </li>

              <li>
                <Link to="/temple">
                  <i class="fa-solid fa-angles-right"></i>
                  Temple
                </Link>
              </li>

              <li>
                <Link to="/pandit">
                  <i class="fa-solid fa-angles-right"></i>
                  Pandit
                </Link>
              </li>

              <li>
                <Link to="/privacypolicy">
                  <i class="fa-solid fa-angles-right"></i>
                  Privacy Policy
                </Link>
              </li>

              <li>
                <Link to="/termsandcondition">
                  <i class="fa-solid fa-angles-right"></i>
                  Terms & Condition
                </Link>
              </li>
            </div>
          </div>

          <div className="col-sm-4">
            <div className="f_heading">Contact Info</div>

            <ul className="info">
              {/* <li>
                <i class="fa-solid fa-location-dot"></i>
                <span>
                  {" "}
                  203 Mangal City, Vijay Nagar, Indore, Madhya Pradesh, India 452010
                </span>
              </li> */}

              <li>
                <i class="fa-solid fa-envelope"></i>{" "}
                <span>
                  <a href="mailto:enquiry@prabhupooja.com">
                    enquiry@prabhupooja.com
                  </a>
                </span>
              </li>

              <li>
                <i class="fa-solid fa-phone"></i>
                <span>
                  <a href="tel:+917225016699">+91 7225016699</a>
                </span>
              </li>
            </ul>

            <ul className="social_icon" style={{ marginTop: "30px" }}>
              <li>
                <Link to="https://www.facebook.com/profile.php?id=61565211141697">
                  <FaFacebook />
                </Link>
              </li>

              <li>
                <Link to="https://www.instagram.com/prabhupoojaofficial?igsh=MWpkY2V5Njl2dzlmeQ==">
                  <FaInstagram />
                </Link>
              </li>

              <li>
                <Link>
                  <FaLinkedin />
                </Link>
              </li>

              <li>
                <Link>
                  <FaYoutube />
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
