import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios"; // Import axios
import "../../styles/muhurat.css";
import api from "../Axios/api";

const Muhurat = () => {
  const [muhurats, setMuhurats] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  // Fetch data from the API on component mount
  useEffect(() => {
    const fetchMuhurats = async () => {
      try {
        const response = await api.get("/muhurat/get"); // Adjust the URL if necessary
        setMuhurats(response.data.data); // Assuming the data is under 'data' key
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch muhurat data");
        setLoading(false);
      }
    };
    fetchMuhurats();
  }, []);

  // Show loading state or error if there's an issue fetching data
  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <>
      <div className="sub_header_muhurat">
        <div className="container">
          <div className="subheader_inner_muhurat">
            <div className="subheader_text_muhurat">
              <h1>Muhurat</h1>
            </div>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link className="btn-link" to="/">
                    Home
                  </Link>
                </li>
                <li className="breadcrumb-item active">Muhurat</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      <div className="muhurat-list">
        <div className="container">
          <div className="row">
            {/* Map through the fetched muhurats data and display them dynamically */}
            {muhurats.map((muhur, index) => (
              <div key={index} className="col-md-4 col-lg-3">
                <div className="muhurat-img-box">
                  <Link to={`/panditmuhuratprofile/${muhur.id}`}>
                    <img src={muhur.image} alt={muhur.name} />
                    <p>{muhur.name}</p>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Muhurat;
